import { APIS } from "../../../common/utils/api";
import { CHANNEL } from "../../../common/utils/common";
import {
  getDateFromServer,
  getDateInUI,
  isMobCorrect,
  validateAddress,
  validateDateFormat,
  validateEmail,
  validateFirstName,
  validatePinCode,
  validateState,
} from "./ValidationEntity";

enum ELEM {
  SAVE = "profile_submit",
  PROFILE_DELETE = "profile_delete",
  TEAM_NAME_INPUT_BOX = "team_name_input_box",
  TEAM_NAME_INPUT = "team_name_input",
  FULL_NAME_INPUT = "full_name_input",
  MOBILE_INPUT = "mobile_input",
  EMAIL_INPUT = "email_input",
  PINCODE_INPUT = "pincode_input",
  STATE_INPUT = "state_input",
  ADDRESS_INPUT = "address_input",
  DOB_INPUT = "date_picker_input",
  DELETE_ACT_CONTAINER = "delete_act_bottomSheet-container",
  DELETE_ACT_BOTTOM_SHEET = "delete_act_bottomSheet",
  DELETE_ACT_BOTTOMSHEET_OVERLY = "delete_act_bottomSheet-overly",
  SURVEY_CLOSE_BTN = "surveyCloseBtn",
  DELETE_ACCT_CTA = "deleteAcctCTA",
  DELETE_REASON = "delete_reason",
  TEXT_AREA_LEN = "textarealen",
  FIRST_NAME_LENGTH = "name_length",
}

enum CSS {
  HIDE = "hide",
  SHOW = "show",
  ACTIVE = "active",
  INPUT_ERR_BORDER = "error-border",
  INPUT_ERR_BTM_BORDER = "error-btmborder",
}

enum MSG {
  REG_TITLE = "LOGIN/SIGNUP",
  SWITCH_TO_LOGIN_TXT = "Login using other methods",
  SWITCH_TO_REG_TXT = "Login / Register using mobile",
  MOBILE_INPUT_PLACEHOLDER_TXT = "Enter Mobile Number",
  EMAIL_INPUT_PLACEHOLDER_TXT = "Enter email address / username",
  GET_STARTED_BTN_LABEL = "GET STARTED",
  LOGIN_BTN_LABEL = "LOGIN",
  INVALID_MOBILE_MSG = "Enter a valid Mobile Number",
  INVALID_EMAIL_MSG = "Enter a valid Email address / username",
  INVALID_USERNAME_MSG = "Enter a valid Username",
  SUCCESS_DELETE_MSG = "Account deletion initiated",
}

enum PATH {
  BASE = "/my11c/static",
  LOGIN = "/login.html",
}

class MyProfileView {
  trackEvent(str1: string, str2: string) {}

  getButtonElememt(param: string) {
    return document.getElementById(param) as HTMLButtonElement;
  }

  getDivElement(param: string) {
    return document.getElementById(param) as HTMLDivElement;
  }
  getInputElement(param: string) {
    return document.getElementById(param) as HTMLInputElement;
  }
  init() {
    this.populateTeamName();
  }
  deleteReasonText: HTMLTextAreaElement | null = null;
  textarealen: HTMLSpanElement | null = null;

  registerDelete() {
    const profileDelete = this.getButtonElememt(ELEM.PROFILE_DELETE);
    this.addClickEvent(profileDelete, this.onProfileDelete);

    const deleteAcctCTA = this.getDivElement(ELEM.DELETE_ACCT_CTA);
    if (deleteAcctCTA) {
      this.addClickEvent(deleteAcctCTA, this.showDeleteActBottomSheet);
    }

    this.deleteReasonText = document.getElementById(
      ELEM.DELETE_REASON
    ) as HTMLTextAreaElement;
    if (this.deleteReasonText) {
      this.deleteReasonText.addEventListener(
        "input",
        this.onDeleteReasonChange.bind(this)
      );
    }
    this.textarealen = document.getElementById(
      ELEM.TEXT_AREA_LEN
    ) as HTMLSpanElement;

    const bottonSheetEle = this.getDivElement(
      ELEM.DELETE_ACT_BOTTOMSHEET_OVERLY
    );
    const surveyCloseBtn = this.getDivElement(ELEM.SURVEY_CLOSE_BTN);

    if (bottonSheetEle) {
      this.addClickEvent(bottonSheetEle, this.closeDeleteActBottomSheet);
    }
    if (surveyCloseBtn) {
      this.addClickEvent(surveyCloseBtn, this.closeDeleteActBottomSheet);
    }
  }
  submitEvent: any;
  registerEvent() {
    const saveBtn = this.getButtonElememt(ELEM.SAVE);
    this.submitEvent = this.onSubmitClick.bind(this);
    saveBtn.addEventListener("click", this.submitEvent);

    const firstName = this.getButtonElememt(ELEM.FULL_NAME_INPUT);
    const firstNameLength = this.getDivElement(ELEM.FIRST_NAME_LENGTH);
    firstName.addEventListener("input", function () {
      var nameLength = this.value.length;
      firstNameLength.textContent = nameLength + "";
    });

    const pinCode = this.getInputElement(ELEM.PINCODE_INPUT);
    if (pinCode !== null) {
      pinCode.addEventListener("blur", this.getPostalCode.bind(this));
    }

    // delete o
    this.registerDelete();

    const inputEleArr = [
      ELEM.FULL_NAME_INPUT,
      ELEM.MOBILE_INPUT,
      ELEM.EMAIL_INPUT,
      ELEM.PINCODE_INPUT,
      ELEM.ADDRESS_INPUT,
      ELEM.DOB_INPUT,
    ];
    inputEleArr.forEach((inputId: string) => {
      const inputEle = this.getInputElement(inputId);
      if (inputEle !== null) {
        inputEle.addEventListener(
          "input",
          this.hideErrorBorderToInput.bind(this, inputId)
        );
      }
    });
  }

  addErrorToTextArea(msg: string) {
    this.addCssClass(this.deleteReasonText, "area-error");
    this.showErrorMsgToInput(ELEM.DELETE_REASON, msg);
  }

  removeErrorToTextArea() {
    this.removeCssClass(this.deleteReasonText, "area-error");
    this.hideErrorMsgToInput(ELEM.DELETE_REASON);
  }

  onDeleteReasonChange() {
    if (this.deleteReasonText) {
      const lenValue = this.deleteReasonText.value.length;
      if (lenValue >= 0 && this.textarealen) {
        this.textarealen.textContent = lenValue + "";
        this.removeErrorToTextArea();
      }
    }
  }

  addClickEvent(ele: HTMLElement, event: () => void) {
    if (ele != null) {
      ele.addEventListener("click", event.bind(this));
    }
  }

  addCssClass(elem: HTMLElement | null, className: string) {
    if (elem != null && !elem.classList.contains(className)) {
      elem.classList.add(className);
    }
  }

  removeCssClass(elem: HTMLElement | null, className: string) {
    if (elem != null && elem.classList.contains(className)) {
      elem.classList.remove(className);
    }
  }

  showErrorBorderToInput(id: string) {
    const inputBox = this.getDivElement(`${id}_box`);
    if (inputBox) {
      this.addCssClass(inputBox, CSS.INPUT_ERR_BORDER);
    }
  }

  showErrorMsgToInput(id: string, msg: string) {
    const inputBox = this.getDivElement(`${id}_msg`);
    if (inputBox) {
      this.removeCssClass(inputBox, CSS.HIDE);
      this.addCssClass(inputBox, CSS.SHOW);
      inputBox.innerHTML = msg;
    }
  }

  hideErrorMsgToInput(id: string) {
    const inputBox = this.getDivElement(`${id}_msg`);
    if (inputBox) {
      this.removeCssClass(inputBox, CSS.SHOW);
      this.addCssClass(inputBox, CSS.HIDE);
    }
  }

  hideErrorBorderToInput(id: string) {
    const inputBox = this.getDivElement(`${id}_box`);
    if (inputBox) {
      this.removeCssClass(inputBox, CSS.INPUT_ERR_BORDER);
      this.hideErrorMsgToInput(id);
    }
  }
  checkAndShowError(
    methodToRun: (...param: any) => string,
    args: any[],
    id: string
  ) {
    const data = methodToRun(...args);
    if (data !== "") {
      this.showErrorBorderToInput(id);
      this.showErrorMsgToInput(id, data);
      // alert(data);
      throw new Error(data);
    }
  }

  closeDeleteActBottomSheet() {
    const deleteActContainer = this.getDivElement(ELEM.DELETE_ACT_CONTAINER);
    const deleteActbottomSheet = this.getDivElement(
      ELEM.DELETE_ACT_BOTTOM_SHEET
    );

    if (deleteActContainer && deleteActbottomSheet) {
      this.removeCssClass(deleteActbottomSheet, CSS.ACTIVE);

      setTimeout(() => {
        this.removeCssClass(deleteActContainer, CSS.ACTIVE);
      }, 400);
    }
  }

  redirectWithBase(redirect: string) {
    if (process.env.BUILD_TYPE === CHANNEL.IPL) {
      return PATH.BASE + redirect;
    }
    return redirect;
  }
  
  showDeleteActBottomSheet = () => {
    this.commonValidations();
    const deleteActContainer = this.getDivElement(ELEM.DELETE_ACT_CONTAINER);
    const deleteActbottomSheet = this.getDivElement(
      ELEM.DELETE_ACT_BOTTOM_SHEET
    );
    if (deleteActContainer && deleteActbottomSheet) {
      this.addCssClass(deleteActContainer, CSS.ACTIVE);
      setTimeout(() => {
        this.addCssClass(deleteActbottomSheet, CSS.ACTIVE);
      }, 100);
    }
  };

  async onProfileDelete() {
    try {
      if (this.deleteReasonText) {
        const reason = this.deleteReasonText.value;
        if (!reason) {
          // alert("Reason for deletion is mandatory");
          this.addErrorToTextArea("Reason for deletion is mandatory");
          return;
        }
        if (reason.length > 1000) {
          this.addErrorToTextArea("Reason size max limit exceeds");
          return;
        }
        let resp = await APIS.sendUserDeleteEmail({
          reasonForDeletion: reason,
          email: this.getInputElement(ELEM.EMAIL_INPUT).value,
        });
        if (resp && resp.success) {
          alert(MSG.SUCCESS_DELETE_MSG);
          this.closeDeleteActBottomSheet();
          window.location.pathname = this.redirectWithBase(PATH.LOGIN);
          // logout user
        } else {
          this.addErrorToTextArea(resp.ErrorMessage);
        }
      }
    } catch (error) {}
  }

  commonValidations() {
    const data: any = {
      fullName: "",
      mobile: "",
      email: "",
      dob: "",
      addrLine1: "",
      addrLine2: "",
      state: "",
      pinCode: "",
      form: "form2",
      ageAbove18: "",
      city: this.city,
    };

    data.fullName = this.getInputElement(ELEM.FULL_NAME_INPUT).value;
    this.checkAndShowError(
      validateFirstName,
      [data.fullName],
      ELEM.FULL_NAME_INPUT
    );

    data.mobile = this.getInputElement(ELEM.MOBILE_INPUT).value;
    this.checkAndShowError(isMobCorrect, [data.mobile], ELEM.MOBILE_INPUT);

    data.email = this.getInputElement(ELEM.EMAIL_INPUT).value;
    this.checkAndShowError(validateEmail, [data.email], ELEM.EMAIL_INPUT);

    data.pinCode = this.getInputElement(ELEM.PINCODE_INPUT).value;
    this.checkAndShowError(
      validatePinCode,
      [data.pinCode, false],
      ELEM.PINCODE_INPUT
    );

    data.state = this.getInputElement(ELEM.STATE_INPUT).value;
    this.checkAndShowError(
      validateState,
      [data.state, false],
      ELEM.STATE_INPUT
    );

    data.addrLine1 = this.getInputElement(ELEM.ADDRESS_INPUT).value;
    data.addrLine2 = data.addrLine1;
    this.checkAndShowError(
      validateAddress,
      [data.addrLine1, false],
      ELEM.ADDRESS_INPUT
    );

    data.dob = getDateInUI(this.getInputElement(ELEM.DOB_INPUT).value);
    this.checkAndShowError(
      validateDateFormat,
      [data.dob, false],
      ELEM.DOB_INPUT
    );
    return data;
  }
  onSubmitClick() {
    try {
      const validatedData = this.commonValidations();
      this.postAddressData(validatedData);
    } catch (error: any) {
      console.error(error);
    }
  }

  async postAddressData(data: any) {
    try {
      let resp = await APIS.saveAddressDetails(data);
      if (resp && resp.hasOwnProperty("user_pan_info")) {
        // Success
        const submitBtn = this.getButtonElememt(ELEM.SAVE);
        // btn_disable
        submitBtn.removeEventListener("click", this.submitEvent);
        this.addCssClass(submitBtn, "btn_disable");

        return;
      }
      if (resp.ErrorMessage) {
        alert(resp.ErrorMessage);
        return;
      }
      // resp = resp.data;
    } catch (error) {}
  }

  city = "";
  async getPostalCode() {
    try {
      const pinCode = this.getInputElement(ELEM.PINCODE_INPUT);
      const stateEle = this.getInputElement(ELEM.STATE_INPUT);
      this.hideErrorBorderToInput(ELEM.STATE_INPUT);
      stateEle.value = "";
      if (pinCode !== null && pinCode.value) {
        let data = await APIS.getPostalCode({ pin: pinCode.value });
        if (data.stateName) {
          stateEle.value = data.stateName;
          this.city = data.districtName;
        }
      }
    } catch (error) {}
  }

  async populateTeamName() {
    try {
      let data = await APIS.getUserDetails({});

      const mobile = this.getInputElement(ELEM.MOBILE_INPUT);
      this.setAndDisable(mobile, data, "mobile");

      const fullName = this.getInputElement(ELEM.FULL_NAME_INPUT);
      this.setAndDisable(fullName, data, "firstName");
      if (data.hasOwnProperty("lastName")) {
        fullName.value += " " + data.lastName;
      }

      const email = this.getInputElement(ELEM.EMAIL_INPUT);
      this.setAndDisable(email, data, "email");

      const pinCode = this.getInputElement(ELEM.PINCODE_INPUT);
      this.setAndDisable(pinCode, data, "pinCode");

      const state = this.getInputElement(ELEM.STATE_INPUT);
      this.setAndDisable(state, data, "state");

      this.city = data.city;

      const address = this.getInputElement(ELEM.ADDRESS_INPUT);
      this.setAndDisable(address, data, "addrLine1");

      if (!(data.hasOwnProperty("dob") && data["dob"] != "")) {
        this.registerEvent();
      } else {
        const dob = this.getInputElement(ELEM.DOB_INPUT);
        dob.value = getDateFromServer(data.dob);
        // this.setAndDisable(dob, data, "dob");

        this.registerDelete();
        mobile.disabled = true;
        fullName.disabled = true;
        email.disabled = true;
        pinCode.disabled = true;
        state.disabled = true;
        address.disabled = true;
        dob.disabled = true;
        this.addCssClass(this.getButtonElememt(ELEM.SAVE), "btn_disable");
      }
    } catch (error) {}
  }

  setAndDisable(ele: HTMLInputElement, data: any, param: string) {
    if (data.hasOwnProperty(param)) {
      ele.value = data[param];
    }
  }
}
export default MyProfileView;
