import { validations } from "../utils/validate";
import { APIS } from "../utils/api";
import {
  CHANNEL,
  getChannelId,
  setLocalStorageItem,
  trackEvent,
} from "../utils/common";

enum ELEM {
  OTP_FORM_MOBILE_ELEMENT = "verify_otp_for_mobile",
  VERIFY_OTP_BTN = "verifyOtp",
  OTP_CLEAR_BTN = "otp_clear_btn",
  CHANGE_MOBILE_BTN = "changeMobileNum",
  RESEND_OTP_TIMER_DIV = "resend_otp_timer",
  OTP_INPUT_FIELD = "otpInputField",
  INCORRECT_OTP_DIV = "msg_invalid_otp",
  VERIFY_OTP_BOX = "verify_otp_box",
  RESEND_OTP_BTN = "resend_otp_btn",
  LOGIN_WITH_PASSWORD = "login_with_password",
  RBD = "rbd",
}

enum CSS {
  HIDE = "hide",
  INPUT_ERR_BORDER = "error-border",
}

enum MSG {
  OTP_SENT_TXT_MOBILE = "OTP sent to ",
  OTP_SENT_TXT_EMAIL = "OTP has been sent to ",
}

enum CHANNELS {
  MEC_WEB = "MEC_WEB",
}

enum URLS {
  RBD_BLOCK = "/rcspa/rbd/",
}

const RESEND_OTP_TIMER_SEC = 30;
const COUNTRY_CODE = "+91-";
const abortController = new AbortController();

class OtpVerificationBaseView {
  mobileNum: string = "";
  email: string = "";
  private inviteCode: string = "";
  otpValue: string = "";
  resendOtpTimerValue: number = RESEND_OTP_TIMER_SEC;
  resendOtpTimer: any = null;
  private sendOtpResp: any = null;
  private sendEmailResp: any = null;
  isRegJourney: boolean = true;
  loginId: string = "";
  reasonCode: number = -1;
  challenge: string = "";
  private transactionId: number = 0;
  userInput: number = 1;
  private channel: string;
  private sessionId: string = "";
  isVerifyingOtp: boolean = false;

  constructor(data?: any) {
    this.channel = data?.channel;
  }

  // PRIVATE FUNCTIONS
  addCssClass(elem: HTMLElement, className: string) {
    if (!elem.classList.contains(className)) {
      elem.classList.add(className);
    }
  }

  removeCssClass(elem: HTMLElement, className: string) {
    if (elem.classList.contains(className)) {
      elem.classList.remove(className);
    }
  }

  private setResponsePage(resp: { responsePage: string; data: any }) {
    let channelId: number = getChannelId();
    let rbd = {
      responsePage: resp.responsePage,
      userId: resp.data?.userId,
    };
    if (this.channel === CHANNELS.MEC_WEB) {
      window.location.pathname = "/mecspa/lobby/";
    } else if (
      channelId === 2 &&
      document.getElementById(ELEM.RBD) &&
      resp.data?.register
    ) {
      sessionStorage.setItem("rbd", JSON.stringify(rbd));
      window.location.pathname = URLS.RBD_BLOCK;
    } else window.location.href = resp.responsePage;
  }

  clearTimerAndSendResp() {
    if (this.resendOtpTimer != null) {
      clearTimeout(this.resendOtpTimer);
      this.resendOtpTimer = null;
    }
    this.sendOtpResp = null;
    this.sendEmailResp = null;
  }

  setFieldDataAndTimer() {
    this.clearTimerAndSendResp();
    this.mobileNum = "";
    this.inviteCode = "";
  }

  showInvalidOtpError(errMsg: string = "Incorrect OTP") {
    let incorrectOtpContainer = document.getElementById(
      ELEM.INCORRECT_OTP_DIV
    ) as HTMLDivElement | HTMLParagraphElement;
    incorrectOtpContainer.innerHTML = errMsg;
    this.removeCssClass(incorrectOtpContainer, CSS.HIDE);

    let otpInputBox = document.getElementById(
      ELEM.VERIFY_OTP_BOX
    ) as HTMLDivElement;
    this.addCssClass(otpInputBox, CSS.INPUT_ERR_BORDER);
  }

  private updateMobileNum() {
    if (!window.international) {
      let mobileNumText = document.getElementById(
        ELEM.OTP_FORM_MOBILE_ELEMENT
      ) as HTMLSpanElement;
      mobileNumText.innerHTML = COUNTRY_CODE + this.mobileNum;
    }
  }

  private stopResendOtpTimer() {
    if (this.resendOtpTimer != null) {
      clearTimeout(this.resendOtpTimer);
      this.resendOtpTimer = null;
    }
  }

  startResendOtpTimer() {
    if (this.resendOtpTimer != null) {
      clearTimeout(this.resendOtpTimer);
    }

    this.resendOtpTimer = setTimeout(() => {
      this.resendOtpTimerValue--;
      let resendOtpContainer = document.getElementById(
        ELEM.RESEND_OTP_TIMER_DIV
      ) as HTMLDivElement;
      if (this.resendOtpTimerValue > 0) {
        resendOtpContainer.innerHTML =
          "Resend OTP in 00." +
          (this.resendOtpTimerValue >= 10
            ? this.resendOtpTimerValue
            : "0" + this.resendOtpTimerValue);
        this.startResendOtpTimer();
      } else {
        this.resendOtpTimer = null;
        this.showResendOtpOptions();
      }
    }, 1000);
  }

  showResendOtpOptions() {
    let resendOtpContainer = document.getElementById(
      ELEM.RESEND_OTP_TIMER_DIV
    ) as HTMLDivElement;
    this.addCssClass(resendOtpContainer, CSS.HIDE);

    let resendOtpBtn = document.getElementById(
      ELEM.RESEND_OTP_BTN
    ) as HTMLDivElement;
    this.removeCssClass(resendOtpBtn, CSS.HIDE);
  }

  hideResendOtpOptions() {
    let resendOtpContainer = document.getElementById(
      ELEM.RESEND_OTP_TIMER_DIV
    ) as HTMLDivElement;
    this.removeCssClass(resendOtpContainer, CSS.HIDE);

    let resendOtpBtn = document.getElementById(
      ELEM.RESEND_OTP_BTN
    ) as HTMLDivElement;
    this.addCssClass(resendOtpBtn, CSS.HIDE);
  }

  private getOtpIdentifier(): string {
    let identifier: string = "";

    if (
      this.sendOtpResp != null &&
      this.sendOtpResp.data &&
      this.sendOtpResp.data.uniqueIdentifier
    ) {
      identifier = this.sendOtpResp.data.uniqueIdentifier;
    }

    return identifier;
  }

  private getUserSessionId(): string {
    if (this.sendEmailResp != null && this.sendEmailResp.Session) {
      this.sessionId = this.sendEmailResp.Session;
    }
    return this.sessionId;
  }

  private getChallenge(): string {
    let challenge: string = "";

    if (
      this.sendOtpResp != null &&
      this.sendOtpResp.data &&
      this.sendOtpResp.data.challenge
    ) {
      challenge = this.sendOtpResp.data.challenge;
    }

    return challenge;
  }

  private getTransactionId(): number {
    let transactionId: number = 0;

    if (
      this.sendOtpResp != null &&
      this.sendOtpResp.data &&
      (this.sendOtpResp.data.otpTransactionId ||
        this.sendOtpResp.data.transactionId)
    ) {
      transactionId =
        this.sendOtpResp.data.otpTransactionId ||
        this.sendOtpResp.data.transactionId;
    }

    return transactionId;
  }

  private isExistingUser(): boolean {
    let flag: boolean = false;

    if (
      this.sendOtpResp != null &&
      this.sendOtpResp.data &&
      this.sendOtpResp.data.isLogin == true
    ) {
      flag = true;
    }

    return flag;
  }

  inValidOtp = "Invalid OTP entered";

  private verifyRegistrationOtp() {
    if (this.channel === CHANNEL.IPL) {
      APIS.verifyOtpRegStep3(
        this.mobileNum,
        this.otpValue,
        this.getOtpIdentifier()
      )
        .then((resp) => {
          if (resp.success) {
            trackEvent(
              "action_succeeded",
              "registration/otpverification/authentication"
            );
            abortController.abort();
            this.stopResendOtpTimer();
            setLocalStorageItem("mobile", this.mobileNum);
            sessionStorage.setItem("international", "false");

            window.location.href = this.redirect + "?isIn=true";
          } else {
            const errMsg =
              resp.ErrorCode == 84 ? this.inValidOtp : resp.ErrorMessage;
            this.showInvalidOtpError(errMsg);
            trackEvent("action_failed", "registration_failed", {
              error_message: resp.ErrorMessage || "Incorrect OTP",
            });
          }
          this.isVerifyingOtp = false;
        })
        .catch((err) => {
          console.error("verify OTP step 2", err.message);
          this.showInvalidOtpError(this.inValidOtp);
          this.isVerifyingOtp = false;
        });
    } else {
      APIS.verifyOtpRegStep2(
        this.mobileNum,
        this.otpValue,
        this.getOtpIdentifier()
      )
        .then((resp) => {
          if (resp.success) {
            trackEvent(
              "action_succeeded",
              "registration/otpverification/authentication"
            );
            abortController.abort();
            this.stopResendOtpTimer();
            this.setResponsePage(resp);
          } else {
            if (resp.ErrorCode == 84) {
              this.showInvalidOtpError(resp.ErrorMessage);
            }
            this.showInvalidOtpError();
            trackEvent("action_failed", "registration_failed", {
              error_message: resp.ErrorMessage || "Incorrect OTP",
            });
          }
        })
        .catch((err) => {
          console.error("verify OTP step 2", err.message);
          this.showInvalidOtpError();
        });
    }
  }

  private verifyExternalRegistrationOtp() {
    APIS.verifyExternalOtpReg(
      this.email,
      this.otpValue,
      this.getUserSessionId()
    )
      .then((resp) => {
        if (resp.success) {
          trackEvent(
            "action_succeeded",
            "registration/otpverification/authentication"
          );
          abortController.abort();
          this.stopResendOtpTimer();
          setLocalStorageItem("email", this.email);
          sessionStorage.setItem("international", "true");
          window.location.href = this.redirect;
        } else {
          this.showInvalidOtpError(resp.ErrorMessage);
          trackEvent("action_failed", "registration_failed", {
            error_message: resp.ErrorMessage || "Incorrect OTP",
          });
        }
        this.isVerifyingOtp = false;
      })
      .catch((err) => {
        console.error("verify OTP for international users", err.message);
        this.showInvalidOtpError(this.inValidOtp);
        this.isVerifyingOtp = false;
      });
  }

  private verifyLoginOtp() {
    if (this.channel === CHANNEL.IPL) {
      APIS.verifyOtpRegStep2ForLogin2(
        this.mobileNum,
        this.otpValue,
        this.getChallenge()
      )
        .then((resp) => {
          if (resp.success) {
            this.stopResendOtpTimer();
            setLocalStorageItem("mobile", this.mobileNum);
            sessionStorage.setItem("international", "false");

            trackEvent(
              "action_succeeded",
              "login/otpverification/authentication"
            );
            abortController.abort();
            window.location.href = this.redirect + "?isIn=true";
          } else {
            trackEvent("action_failed", "login_failed", {
              error_message: resp.ErrorMessage || "Incorrect OTP",
            });
            const errorMsg = resp.ErrorMessage || this.inValidOtp;
            this.showInvalidOtpError(errorMsg);
          }
          this.isVerifyingOtp = false;
        })
        .catch((err) => {
          console.error("verify OTP step 2", err.message);
          this.showInvalidOtpError(this.inValidOtp);
          this.isVerifyingOtp = false;
        });
    } else {
      APIS.verifyOtpRegStep2ForLogin(
        this.mobileNum,
        this.otpValue,
        this.getChallenge()
      )
        .then((resp) => {
          if (resp.success) {
            this.stopResendOtpTimer();
            trackEvent(
              "action_succeeded",
              "login/otpverification/authentication"
            );
            abortController.abort();
            this.setResponsePage(resp);
          } else {
            trackEvent("action_failed", "login_failed", {
              error_message: resp.ErrorMessage || "Incorrect OTP",
            });
            this.showInvalidOtpError();
          }
        })
        .catch((err) => {
          console.error("verify OTP step 2", err.message);
          this.showInvalidOtpError();
        });
    }
  }

  private initVerifyOtp() {
    if (this.isExistingUser()) {
      this.verifyLoginOtp();
    } else {
      this.verifyRegistrationOtp();
    }
  }

  invokeResendOtp(otpOnCall: boolean = false) {
    let transactionId: number = 0;
    let otpType: number = 0;
    if (this.isRegJourney && !this.isExistingUser()) {
      transactionId = this.getTransactionId();
      otpType = 8;
    } else {
      transactionId = this.transactionId;
      otpType = 6;
    }

    if (this.channel === CHANNEL.IPL) {
      APIS.resendOtp2(otpType, transactionId, otpOnCall, this.mobileNum)
        .then((resp) => {})
        .catch((err) => {
          console.log("invoke resend otp err", err.message);
        });
    } else {
      APIS.resendOtp(otpType, transactionId, otpOnCall, this.mobileNum)
        .then((resp) => {})
        .catch((err) => {
          console.log("invoke resend otp err", err.message);
        });
    }
  }

  invokeSendEmailExternal() {
    APIS.sendEmailExternal(this.email)
      .then((resp) => {
        if (resp.Session) {
          this.sendEmailResp = resp;
        } else {
          const errorMsg = resp.errorMessage || this.inValidOtp;
          this.showInvalidOtpError(errorMsg);
        }
      })
      .catch((err) => {
        console.log("invoke resend otp err", err.message);
      });
  }

  private updateOtpSentText() {
    let msgTxt: string = this.loginId;
    if (this.reasonCode == 601 || this.reasonCode == 603) {
      msgTxt =
        this.userInput != 2
          ? this.loginId
          : "registered email ID and mobile no.";
    } else if (this.reasonCode == 605 || this.reasonCode == 606) {
      msgTxt = this.userInput == 3 ? this.loginId : "registered email ID";
    }

    let mobileNumText = document.getElementById(
      ELEM.OTP_FORM_MOBILE_ELEMENT
    ) as HTMLSpanElement;
    mobileNumText.innerHTML = msgTxt;

    this.showLoginWithPassword();
  }

  private showLoginWithPassword() {
    if (this.reasonCode == 603 || this.reasonCode == 606) {
      let loginWithPassword = document.getElementById(
        ELEM.LOGIN_WITH_PASSWORD
      ) as HTMLDivElement;
      this.removeCssClass(loginWithPassword, CSS.HIDE);
    }
  }

  private verifyOtpForLogin() {
    APIS.verifyOtpLoginStep2(
      this.loginId,
      this.otpValue,
      this.challenge,
      this.reasonCode
    )
      .then((resp) => {
        if (resp.success) {
          this.stopResendOtpTimer();
          this.setResponsePage(resp);
        } else {
          // console.log("verifyOtpForLogin", resp.ErrorCode);
          this.showInvalidOtpError();
          trackEvent("action_failed", "login_failed", {
            error_message: resp.ErrorMessage || "Incorrect OTP",
          });
        }
        this.isVerifyingOtp = false;
      })
      .catch((err) => {
        console.log("verifyOtpForLogin", err.message);
        this.showInvalidOtpError();
        this.isVerifyingOtp = false;
      });
  }

  redirect = "";
  setRedirect(data?: any) {
    if (data && data.redirect) {
      this.redirect = data.redirect;
    }
  }
  // PUBLIC FUNCTIONS
  onClickVerifyOtp() {
    this.isVerifyingOtp = true;
    trackEvent("clicked", "verify_with_otp");
    this.otpValue = (
      document.getElementById(ELEM.OTP_INPUT_FIELD) as HTMLInputElement
    ).value;
    const OTP_MAX_LENGTH = window.international ? 8 : 6;

    if (!validations.isOtp(this.otpValue, OTP_MAX_LENGTH)) {
      if (this.otpValue.length == 0) {
        trackEvent("action_failed", "otp_verification_error", {
          "error message": "Please enter OTP",
        });
        this.showInvalidOtpError("Please enter OTP");
      } else {
        trackEvent("action_failed", "otp_verification_error", {
          "error message": "Invalid OTP entered",
        });
        this.showInvalidOtpError("Invalid OTP entered");
      }

      return;
    }

    if (window.international) {
      this.verifyExternalRegistrationOtp();
    } else if (this.isRegJourney) {
      this.initVerifyOtp();
    } else {
      this.verifyOtpForLogin();
    }
  }

  onClickResendOtp() {
    this.hideResendOtpOptions();
    this.initResendTimer();
    this.invokeResendOtp();
    this.autoFetchOTP();
    trackEvent("clicked", "resent_otp");
  }

  onClickGetOtpOnCall() {
    abortController.abort();
    this.hideResendOtpOptions();
    this.initResendTimer();
    this.invokeResendOtp(true);

    trackEvent("clicked", "get_otp_on_call");
  }

  onClickChangeMobile() {
    this.resetOtpScreen();
    this.showRegForm();

    trackEvent("clicked", "change_number");
  }

  showOtpClearBtn() {
    let clearBtn = document.getElementById(
      ELEM.OTP_CLEAR_BTN
    ) as HTMLSpanElement;
    this.removeCssClass(clearBtn, CSS.HIDE);
  }

  hideOtpClearBtn() {
    let clearBtn = document.getElementById(
      ELEM.OTP_CLEAR_BTN
    ) as HTMLSpanElement;
    this.addCssClass(clearBtn, CSS.HIDE);
  }

  onChangeOtpInput(evt: Event) {
    let inputValue = (evt.target as HTMLInputElement).value;
    let numOfChars = inputValue.length;

    if (numOfChars > 0) {
      this.showOtpClearBtn();
    } else {
      this.hideOtpClearBtn();
    }
  }

  onClickOtpClearBtn() {
    this.hideOtpClearBtn();
    (document.getElementById(ELEM.OTP_INPUT_FIELD) as HTMLInputElement).value =
      "";
  }

  setIsRegJourney(val: boolean) {
    this.isRegJourney = val;
  }

  setLoginId(loginId: string) {
    this.loginId = loginId;
  }

  setChallenge(challenge: string) {
    this.challenge = challenge;
  }

  setTransactionId(val: number) {
    this.transactionId = val;
  }

  setUserInputValue(val: number) {
    this.userInput = val;
  }

  setReasonCode(reasonCode: number) {
    this.reasonCode = reasonCode;
    this.updateOtpSentText();
  }

  setMobileNum(mobileNum: string) {
    this.mobileNum = mobileNum;
    this.updateMobileNum();
  }

  setEmail(email: string) {
    this.email = email;
    this.updateMobileNum();
  }

  setInviteCode(inviteCode: string) {
    this.inviteCode = inviteCode;
  }

  initResendTimer() {
    this.resendOtpTimerValue = RESEND_OTP_TIMER_SEC;

    let resendOtpContainer = document.getElementById(
      ELEM.RESEND_OTP_TIMER_DIV
    ) as HTMLDivElement;
    resendOtpContainer.innerHTML =
      "Resend OTP in 00." + this.resendOtpTimerValue;
    this.startResendOtpTimer();
  }
  autoFetchOTP() {
    console.log("auto fetch otp started");
    if ("OTPCredential" in window) {
      let otpInputField = document.getElementById(
        ELEM.OTP_INPUT_FIELD
      ) as HTMLInputElement;
      if (!otpInputField) return;

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: abortController.signal,
        })
        .then((otp) => {
          otpInputField.value = otp!.code;
          this.onClickVerifyOtp();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  initFetchOtp() {
    this.autoFetchOTP();
    if (this.channel === CHANNEL.IPL) {
      APIS.getOtpRegStep2(this.mobileNum, this.inviteCode)
        .then((resp) => {
          this.sendOtpResp = resp;
        })
        .catch((err) => {
          console.error("sendOtpResp", err.message);
        });
    } else {
      APIS.getOtpRegStep1(this.mobileNum, this.inviteCode)
        .then((resp) => {
          this.sendOtpResp = resp;
        })
        .catch((err) => {
          console.error("sendOtpResp", err.message);
        });
    }
  }

  onClickLoginWithPwd() {
    this.resetOtpScreen();
    this.showPwdForm(
      this.loginId,
      this.reasonCode,
      this.challenge,
      this.userInput
    );

    trackEvent("clicked", "login_with_password");
  }

  onClickOtpInputField() {
    trackEvent("clicked", "verify_with_otp_shown");
  }

  resetOtpScreen() {
    this.setFieldDataAndTimer();

    let otpInputField = document.getElementById(
      ELEM.OTP_INPUT_FIELD
    ) as HTMLInputElement;
    otpInputField.value = "";

    let otpClearBtn = document.getElementById(
      ELEM.OTP_CLEAR_BTN
    ) as HTMLSpanElement;
    this.addCssClass(otpClearBtn, CSS.HIDE);

    let resendOtpBtn = document.getElementById(
      ELEM.RESEND_OTP_BTN
    ) as HTMLDivElement;
    this.addCssClass(resendOtpBtn, CSS.HIDE);

    let verifyOtpBox = document.getElementById(
      ELEM.VERIFY_OTP_BOX
    ) as HTMLDivElement;
    this.removeCssClass(verifyOtpBox, CSS.INPUT_ERR_BORDER);

    let incorrectOtpContainer = document.getElementById(
      ELEM.INCORRECT_OTP_DIV
    ) as HTMLDivElement;
    this.addCssClass(incorrectOtpContainer, CSS.HIDE);

    let resendOtpContainer = document.getElementById(
      ELEM.RESEND_OTP_TIMER_DIV
    ) as HTMLDivElement;
    this.removeCssClass(resendOtpContainer, CSS.HIDE);

    let loginWithPassword = document.getElementById(
      ELEM.LOGIN_WITH_PASSWORD
    ) as HTMLDivElement;
    this.addCssClass(loginWithPassword, CSS.HIDE);
  }

  showRegForm() {}
  showLoginForm() {}
  showPwdForm(
    loginId: string,
    reasonCode: number,
    challenge: string,
    userInput: number
  ) {}
}

export default OtpVerificationBaseView;
