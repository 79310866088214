import { APIS } from "../../../common/utils/api";
import { CHANNEL } from "../../../common/utils/common";
import {
  getDateInUI,
  validateDateFormat,
  validateFirstName,
} from "./ValidationEntity";

enum ELEM {
  SAVE = "profile_submit",
  FULL_NAME_INPUT = "full_name_input",
  EMAIL_INPUT = "email_input",
  DOB_INPUT = "date_picker_input",
  DELETE_ACCT_CTA = "deleteAcctCTA",
  TEXT_AREA_LEN = "textarealen",
  FIRST_NAME_LENGTH = "name_length",
}

enum CSS {
  HIDE = "hide",
  SHOW = "show",
  ACTIVE = "active",
  INPUT_ERR_BORDER = "error-border",
  INPUT_ERR_BTM_BORDER = "error-btmborder",
}

enum MSG {
  SUCCESS_DELETE_MSG = "Account deletion initiated",
}

enum PATH {
  BASE = "/my11c/static",
  LOGIN = "/login-international.html",
}

class MyProfileInternationalView {
  trackEvent(str1: string, str2: string) {}

  getButtonElememt(param: string) {
    return document.getElementById(param) as HTMLButtonElement;
  }

  getDivElement(param: string) {
    return document.getElementById(param) as HTMLDivElement;
  }
  getInputElement(param: string) {
    return document.getElementById(param) as HTMLInputElement;
  }
  init() {
    this.populateTeamName();
  }

  registerDelete() {
    const deleteAcctCTA = this.getDivElement(ELEM.DELETE_ACCT_CTA);
    if (deleteAcctCTA) {
      this.addClickEvent(deleteAcctCTA, this.onProfileDelete);
    }
  }

  submitEvent: any;
  registerEvent() {
    const saveBtn = this.getButtonElememt(ELEM.SAVE);
    this.submitEvent = this.onSubmitClick.bind(this);
    saveBtn.addEventListener("click", this.submitEvent);
    const firstName = this.getButtonElememt(ELEM.FULL_NAME_INPUT);
    const firstNameLength = this.getDivElement(ELEM.FIRST_NAME_LENGTH);
    firstName.addEventListener("input", function () {
      var nameLength = this.value.length;
      firstNameLength.textContent = nameLength + "";
    });
    // delete o
    this.registerDelete();

    const inputEleArr = [
      ELEM.FULL_NAME_INPUT,
      ELEM.EMAIL_INPUT,
      ELEM.DOB_INPUT,
    ];
    inputEleArr.forEach((inputId: string) => {
      const inputEle = this.getInputElement(inputId);
      if (inputEle !== null) {
        inputEle.addEventListener(
          "input",
          this.hideErrorBorderToInput.bind(this, inputId)
        );
      }
    });
  }

  addClickEvent(ele: HTMLElement, event: () => void) {
    if (ele != null) {
      ele.addEventListener("click", event.bind(this));
    }
  }

  addCssClass(elem: HTMLElement | null, className: string) {
    if (elem != null && !elem.classList.contains(className)) {
      elem.classList.add(className);
    }
  }

  removeCssClass(elem: HTMLElement | null, className: string) {
    if (elem != null && elem.classList.contains(className)) {
      elem.classList.remove(className);
    }
  }

  showErrorBorderToInput(id: string) {
    const inputBox = this.getDivElement(`${id}_box`);
    if (inputBox) {
      this.addCssClass(inputBox, CSS.INPUT_ERR_BORDER);
    }
  }

  showErrorMsgToInput(id: string, msg: string) {
    const inputBox = this.getDivElement(`${id}_msg`);
    if (inputBox) {
      this.removeCssClass(inputBox, CSS.HIDE);
      this.addCssClass(inputBox, CSS.SHOW);
      inputBox.innerHTML = msg;
    }
  }

  hideErrorMsgToInput(id: string) {
    const inputBox = this.getDivElement(`${id}_msg`);
    if (inputBox) {
      this.removeCssClass(inputBox, CSS.SHOW);
      this.addCssClass(inputBox, CSS.HIDE);
    }
  }

  hideErrorBorderToInput(id: string) {
    const inputBox = this.getDivElement(`${id}_box`);
    if (inputBox) {
      this.removeCssClass(inputBox, CSS.INPUT_ERR_BORDER);
      this.hideErrorMsgToInput(id);
    }
  }
  checkAndShowError(
    methodToRun: (...param: any) => string,
    args: any[],
    id: string
  ) {
    const data = methodToRun(...args);
    if (data !== "") {
      this.showErrorBorderToInput(id);
      this.showErrorMsgToInput(id, data);
      // alert(data);
      throw new Error(data);
    }
  }

  redirectWithBase(redirect: string) {
    if (process.env.BUILD_TYPE === CHANNEL.IPL) {
      return PATH.BASE + redirect;
    }
    return redirect;
  }

  async onProfileDelete() {
    let resp = await APIS.triggerInternationalUserDeletion({});
    if (resp && resp.success) {
      alert(MSG.SUCCESS_DELETE_MSG);
      window.location.pathname = this.redirectWithBase(PATH.LOGIN);
      //logout user
    } else {
      alert(resp.errorMessage);
    }
  }

  commonValidations() {
    const data: any = {
      fullName: "",
      dateOfBirth: "",
    };

    data.fullName = this.getInputElement(ELEM.FULL_NAME_INPUT).value;
    this.checkAndShowError(
      validateFirstName,
      [data.fullName],
      ELEM.FULL_NAME_INPUT
    );

    const dateInUI = getDateInUI(this.getInputElement(ELEM.DOB_INPUT).value);
    data.dateOfBirth = this.getInputElement(ELEM.DOB_INPUT).value;
    this.checkAndShowError(
      validateDateFormat,
      [dateInUI, false],
      ELEM.DOB_INPUT
    );
    return data;
  }

  onSubmitClick() {
    try {
      const validatedData = this.commonValidations();
      this.postAddressData(validatedData);
    } catch (error: any) {
      console.error(error);
    }
  }

  async postAddressData(data: any) {
    try {
      let resp = await APIS.saveInternationalUserDetails(data);
      if (resp && resp.success) {
        // Success
        const submitBtn = this.getButtonElememt(ELEM.SAVE);
        // btn_disable
        submitBtn.removeEventListener("click", this.submitEvent);
        this.addCssClass(submitBtn, "btn_disable");
        return;
      }
      if (resp.errorMessage) {
        alert(resp.errorMessage);
        return;
      }
    } catch (error) {}
  }

  async populateTeamName() {
    try {
      let data = await APIS.getInternationalUserDetails({});

      const email = this.getInputElement(ELEM.EMAIL_INPUT);
      this.setAndDisable(email, data, "email");
      email.disabled = true;

      const fullName = this.getInputElement(ELEM.FULL_NAME_INPUT);
      this.setAndDisable(fullName, data, "fullName");

      if (!(data.hasOwnProperty("dateOfBirth") && data["dateOfBirth"] != "")) {
        this.registerEvent();
      } else {
        const dob = this.getInputElement(ELEM.DOB_INPUT);
        dob.value = data.dateOfBirth;

        this.registerDelete();
        fullName.disabled = true;
        dob.disabled = true;
        this.addCssClass(this.getButtonElememt(ELEM.SAVE), "btn_disable");
      }
    } catch (error) {}
  }

  setAndDisable(ele: HTMLInputElement, data: any, param: string) {
    if (data.hasOwnProperty(param)) {
      ele.value = data[param];
    }
  }
}
export default MyProfileInternationalView;
