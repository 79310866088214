import { 
    asyncScriptLoader,
    getChannelId,
    isFantasyApp 
} from './common';

import { APIS } from './api';
import { validations } from './validate';
import { trackEvent } from './common';

declare global {
    interface Window {
        facebook_app_id:any;
        fantasy_facebook_app_id:any;
        fbAsyncInit:any;
        FB:any;
        LoginLocationSwitch:any;
        getGeoLocation:any;
    }
}

enum ErrorMsg {
    eCode_2   = 'Account has been closed. For further information, please contact fairplay@<domainName>.com',
    eCode_8   = 'You are not authorized to associate your <productName> account with Facebook Account from this network',
    eCode_12  = 'Multiple Login Failure Attempts',
    eCode_13  = 'Account with this email ID already exists. If you are an exiting user, please Sign in',
    eCode_14  = 'Account is already associated to another Facebook account',
    eCode_15  = 'Invalid Username/Email or Password',
    eCode_16  = 'Something went wrong! Try again after sometime.',
    eCode_17  = 'Something went wrong! Try again after sometime.',
    eCode_18  = 'Human validation failed',
    eCode_20  = 'Facebook account is already associated with another <productName> account'
}

enum ELEM {
    FB_BTN = 'fb_btn',
    POPUP_DIV = 'social_popup',
    MOBILE_INPUT_POPUP = 'social_mobile_input',
    WELCOME_TXT = 'fb_welcome_txt',
    MOBILE_FIELD_BOX = 'fb_mobile_field_box',
    MOBILE_FIELD = 'fb_mobile_field',
    MOBILE_ERROR = 'fb_mobile_error',
    INVITE_FIELD_BOX = 'fb_invite_field_box',
    INVITE_FIELD = 'fb_invite_field',
    INVITE_ERROR = 'fb_invite_error',
    SUBMIT_BTN = 'fb_submit',
    REG_EMAIL_FIELD_BOX = 'social_email_field_box',
    REG_EMAIL_FIELD = 'social_email_field',
    REG_EMAIL_ERR = 'social_email_err',
    SWITCH_EXISTING_USER = 'social_existing_user',

    OTP_POPUP = 'social_otp',
    OTP_SENT_MOBILE_TXT = 'social_otp_mobile_txt',
    CHANGE_MOBILE_BTN = 'social_change_mobile',
    OTP_FIELD_BOX = 'social_otp_box',
    OTP_FIELD = 'social_otp_field',
    OTP_ERROR = 'social_invalid_otp',
    GET_OTP_ON_CALL = 'social_get_opt_call_btn',
    RESEND_OTP_TIMER = 'social_resend_otp_timer',
    RESEND_OTP_BTN = 'social_resend_otp_btn',
    VERIFY_OTP = 'social_verify_otp',
    SWITCH_EXISTING_USER_2 = 'social_existing_user_2',

    CHANGE_MOBILE_POPUP = 'social_change_mobile_popup',
    CURRENT_MOBILE = 'social_current_mobile',
    NEW_MOBILE_BOX = 'social_new_mobile_box',
    NEW_MOBILE_FIELD = 'social_new_mobile',
    NEW_MOBILE_ERR = 'social_new_mobile_err',
    SAVE_MOBILE = 'social_save_mobile',
    CANCEL_MOBILE = 'social_cancel_mobile',

    ERROR_POPUP = 'social_error_popup',
    ERROR_TITLE = 'social_error_title',
    ERROR_MSG = 'social_error_msg',
    ERROR_OK = 'social_error_ok',

    LINK_EXISTING_USER_POPUP = 'social_link_popup',
    WELCOME_TXT_2 = 'fb_welcome_txt2',
    USERNAME_FIELD_BOX = 'social_username_field_box',
    USERNAME_FIELD = 'social_username_field',
    USERNAME_ERR = 'social_username_err',
    PWD_FIELD_BOX = 'social_pwd_box',
    PWD_FIELD = 'social_pwd',
    PWD_ERROR = 'social_pwd_err',
    FORGOT_PWD = 'social_forgot_pwd',
    LOGIN_BTN = 'social_login_pwd',
    SWITCH_NEW_USER = 'social_switch_reg',
    SHOW_PWD_BTN = 'social_show_pwd',
    HIDE_PWD_BTN = 'social_hide_pwd',
}

enum CSS {
    D_NONE = 'd-none',
    D_BLOCK = 'd-block',
    HIDE = 'hide',
    ERROR_BORDER = 'error-border',
    FB_LOADING = 'loader-fb'
}

const FB_SCRIPT_TAG_ID:string = 'facebook-jssdk';
const FB_SDK_PATH = '//connect.facebook.net/en_US/sdk.js';
const RESEND_OTP_TIMER_SEC = 30;

export default class FBLogin {
    private isFBButtonEnabled = false;
    private isFTApp:boolean = false;
    private FBAppId:string = '';
    private social_userid:string = '';
    private auth_token:string = '';
    private mobileNum:string = '';
    private inviteCode:string = '';
    private email:string = '';
    private otp:string = '';
    private challenge:string = '';
    private userName:string = '';
    private transactionId:number = 0;
    private otpIdentifier:string = '';
    private resendOtpTimerValue:number = RESEND_OTP_TIMER_SEC;
    private resendOtpTimer:any = null;
    private existingUsername:string = '';
    private pwd:string = '';
    private regJourneySCode:number = -1;

    constructor() {
        let initScriptList:string[] = [
            '/fusionassets/widgets/geoLocation/geoLocation.js',
            '/fusionassets/js/desktop/env_config.js',
            '/fusionassets/widgets/2-factor-phone-verification/2-factor-mobile-verification.js'
        ];

        asyncScriptLoader(initScriptList, () => {
            console.log("Init scripts loaded");
        });
    }

    // PRIVATE FUNCTIONS
    private addCssClass(elem:HTMLElement | null, className:string) {
        if(!elem) {
            return;
        }

        if(!elem.classList.contains(className)) {
          elem.classList.add(className);
        }
    }
    
    private removeCssClass(elem:HTMLElement | null, className:string) {
        if(!elem) {
            return;
        }

        if(elem.classList.contains(className)) {
            elem.classList.remove(className);
        }
    }

    private getErrorMsg(errCode:number):string {
        let productName:string = this.isFTApp ? 'My11Circle' : 'RummyCircle';

        let errMsg:string = ErrorMsg['eCode_' + errCode as keyof typeof ErrorMsg] as string;
        return errMsg.replace('<productName>', productName.toLowerCase());
    }

    private getFacebookAppId(cb:Function) {
        let scriptList:string[] = [
            '/fusionassets/js/desktop/env_config.js'
        ];

        asyncScriptLoader(scriptList, () => {
            var channelID = getChannelId();
            if (isFantasyApp(channelID)) {
                this.isFTApp = true;
                this.FBAppId = window.fantasy_facebook_app_id;

                return cb();
            }

            this.isFTApp = false;
            this.FBAppId = window.facebook_app_id;

            return cb();
        });
    }

    private onLoginResponse(resp:any) {
        if(resp.status == 'connected') {
            this.social_userid = resp.authResponse.userID;
            this.auth_token = resp.authResponse.accessToken;

            this.initSocialLogin();
        } else {
            this.showErrorPopup();
        }
    }

    private getGeoLocationInfo() {
        window.getGeoLocation({ locationSwitch: window.LoginLocationSwitch }, this.onGeoLocationInfoCallback.bind(this));
    }

    private onGeoLocationInfoCallback(data:any) {
        if (data.allow) {
            //validation complete make registration call
            this.initSocialLogin();
        }
        else if (data.success && data.geoLocState) {
            //validation complete make registration call
            this.initSocialLogin(data.geoLocState);
        }
        else {
            this.showErrorPopup();
        }
    }

    private initSocialLogin(geoLocState:string = '') {
        APIS.initSocialLogin('FB', this.social_userid, this.auth_token, geoLocState)
        .then(resp => {
            if(resp.statusCode == 2) {
                this.regJourneySCode = resp.sCode;
                if(resp.sCode == 19 || resp.sCode == 324) {
                    this.challenge = resp.challenge;
                    this.userName = resp.userName || '';
                    this.showMobileInputPopup(false);
                }
                else if(resp.sCode == 323) {
                    this.challenge = resp.challenge;
                    this.userName = resp.userName || '';
                    this.showMobileInputPopup(true);
                }
                else if(resp.sCode == 8) {
                    this.showErrorPopup('Not authorized', 'User not allowed to login from this network. For further information,please contact support@rummycircle.com');
                }
                else if(resp.sCode == 16) {
                    this.showErrorPopup('IP missing', 'IP is missing');
                }
                else if(resp.sCode == 2) {
                    this.showErrorPopup('Account Closed', 'Account has been closed.For further information, please contact fairplay@rummycircle.com');
                }
            } 
            else if(resp.success) {
                window.location.href = resp.responsePage;
            } 
            else {
                this.showErrorPopup('Error', resp.ErrorMessage);
            }
        })
        .catch(err => {
            this.showErrorPopup();
        });
    }

    private hideErrorPopup() {
        let errorPopup = document.getElementById(ELEM.ERROR_POPUP) as HTMLDivElement;
        this.addCssClass(errorPopup, CSS.HIDE);
    }

    private showErrorPopup(title:string = 'Error', msg:string = 'Something went wrong, Please try again') {
        (document.getElementById(ELEM.ERROR_TITLE) as HTMLDivElement).innerHTML = title;
        (document.getElementById(ELEM.ERROR_MSG) as HTMLDivElement).innerHTML = msg;

        let errorPopup = document.getElementById(ELEM.ERROR_POPUP) as HTMLDivElement;
        this.removeCssClass(errorPopup, CSS.HIDE);

        let popupDiv = document.getElementById(ELEM.POPUP_DIV) as HTMLDivElement;
        if(popupDiv.classList.contains(CSS.D_NONE)) {
            this.hideMobileInputPopup();

            this.removeCssClass(popupDiv, CSS.D_NONE);
            this.addCssClass(popupDiv, CSS.D_BLOCK);
        }
    }

    private enableFBButton() {
        this.isFBButtonEnabled = true;

        let fbButton = document.getElementById(ELEM.FB_BTN) as HTMLDivElement;
        this.removeCssClass(fbButton, CSS.FB_LOADING);
        
        let popupDiv = document.getElementById(ELEM.POPUP_DIV) as HTMLDivElement;
        this.removeCssClass(popupDiv, CSS.D_BLOCK);
        this.addCssClass(popupDiv, CSS.D_NONE);
    }

    private hideMobileInputPopup() {
        this.hideMobileError();
        this.hideInviteError();
        this.hideEmailError();

        let mobileInputPopup = document.getElementById(ELEM.MOBILE_INPUT_POPUP) as HTMLDivElement;
        this.addCssClass(mobileInputPopup, CSS.HIDE);
    }

    private fetchFBUserEmail() {
        window.FB.api('/me', { locale: 'en_US', fields: 'name, email' }, this.onFetchEmailComplete.bind(this));
    }

    private showMobileInputPopup(withEmail:boolean) {
        if(!withEmail && this.email.length == 0) {
            this.fetchFBUserEmail();
            return;
        }

        let firstName:string = this.userName.split(' ')[0];
        let nameTxt = document.getElementById(ELEM.WELCOME_TXT) as HTMLSpanElement;
        nameTxt.innerHTML = `Welcome <b>${firstName},</b>`;

        this.hideMobileError();
        this.hideInviteError();
        this.hideEmailError();

        let emailFieldParent = (document.getElementById(ELEM.REG_EMAIL_FIELD_BOX) as HTMLInputElement).parentElement;
        if(!withEmail) {
            this.addCssClass(emailFieldParent, CSS.HIDE);
        } else {
            this.removeCssClass(emailFieldParent, CSS.HIDE);
        }

        let mobileInputPopup = document.getElementById(ELEM.MOBILE_INPUT_POPUP) as HTMLDivElement;
        this.removeCssClass(mobileInputPopup, CSS.HIDE);

        let popupDiv = document.getElementById(ELEM.POPUP_DIV) as HTMLDivElement;
        this.removeCssClass(popupDiv, CSS.D_NONE);
        this.addCssClass(popupDiv, CSS.D_BLOCK);

        trackEvent('view_displayed', 'fb_phone_form_shown');
    }

    private hideMobileError() {
        let mobileErr = document.getElementById(ELEM.MOBILE_ERROR) as HTMLDivElement;
        this.addCssClass(mobileErr, CSS.HIDE);
        mobileErr.innerHTML = 'Enter a valid Mobile Number';

        let mobileFieldBox = document.getElementById(ELEM.MOBILE_FIELD_BOX) as HTMLDivElement;
        this.removeCssClass(mobileFieldBox, CSS.ERROR_BORDER);
    }

    private showMobileError(errMsg:string = '') {
        let mobileErr = document.getElementById(ELEM.MOBILE_ERROR) as HTMLDivElement;
        this.removeCssClass(mobileErr, CSS.HIDE);

        if(errMsg.length > 0) {
            mobileErr.innerHTML = errMsg;
        }

        let mobileFieldBox = document.getElementById(ELEM.MOBILE_FIELD_BOX) as HTMLDivElement;
        this.addCssClass(mobileFieldBox, CSS.ERROR_BORDER);
    }

    private hideInviteError() {
        let inviteErr = document.getElementById(ELEM.INVITE_ERROR) as HTMLDivElement;
        this.addCssClass(inviteErr, CSS.HIDE);

        let inviteFieldBox = document.getElementById(ELEM.INVITE_FIELD_BOX) as HTMLDivElement;
        this.removeCssClass(inviteFieldBox, CSS.ERROR_BORDER);
    }

    private showInviteError() {
        let inviteErr = document.getElementById(ELEM.INVITE_ERROR) as HTMLDivElement;
        this.removeCssClass(inviteErr, CSS.HIDE);

        let inviteFieldBox = document.getElementById(ELEM.INVITE_FIELD_BOX) as HTMLDivElement;
        this.addCssClass(inviteFieldBox, CSS.ERROR_BORDER);
    }

    private hideEmailError() {
        let emailErr = document.getElementById(ELEM.REG_EMAIL_ERR) as HTMLDivElement;
        this.addCssClass(emailErr, CSS.HIDE);

        let emailFieldBox = document.getElementById(ELEM.REG_EMAIL_FIELD_BOX) as HTMLDivElement;
        this.removeCssClass(emailFieldBox, CSS.ERROR_BORDER);
    }

    private showEmailError() {
        let emailErr = document.getElementById(ELEM.REG_EMAIL_ERR) as HTMLDivElement;
        this.removeCssClass(emailErr, CSS.HIDE);

        let emailFieldBox = document.getElementById(ELEM.REG_EMAIL_FIELD_BOX) as HTMLDivElement;
        this.addCssClass(emailFieldBox, CSS.ERROR_BORDER);
    }

    private getOtp() {
        APIS.getSocialRegOtp(this.mobileNum, this.challenge, this.email, this.inviteCode)
        .then(resp => {
            if(resp.success == 'true') {
                this.otpIdentifier = resp.data.uniqueIdentifier;
                this.transactionId = resp.data.otpTransactionId;

                this.hideMobileInputPopup();
                this.showOtpVerificationPopup();
            } else {
                this.showMobileError(resp.ErrorMessage);
            }
        })
        .catch(err => {
            this.showErrorPopup();
        });
    }

    private showResendOtpOptions() {
        let resendOtpContainer = document.getElementById(ELEM.RESEND_OTP_TIMER) as HTMLDivElement;
        this.addCssClass(resendOtpContainer, CSS.HIDE);

        let resendBtn = document.getElementById(ELEM.RESEND_OTP_BTN) as HTMLDivElement;
        this.removeCssClass(resendBtn, CSS.HIDE);

        let getOtpCall = document.getElementById(ELEM.GET_OTP_ON_CALL) as HTMLDivElement;
        this.removeCssClass(getOtpCall, CSS.HIDE);
    }

    private hideResendOtpOptions() {
        let resendOtpContainer = document.getElementById(ELEM.RESEND_OTP_TIMER) as HTMLDivElement;
        this.removeCssClass(resendOtpContainer, CSS.HIDE);

        let resendBtn = document.getElementById(ELEM.RESEND_OTP_BTN) as HTMLDivElement;
        this.addCssClass(resendBtn, CSS.HIDE);

        let getOtpCall = document.getElementById(ELEM.GET_OTP_ON_CALL) as HTMLDivElement;
        this.addCssClass(getOtpCall, CSS.HIDE);
    }

    private showIncorrectOtp() {
        let otpBox = document.getElementById(ELEM.OTP_FIELD_BOX) as HTMLDivElement;
        this.addCssClass(otpBox, CSS.ERROR_BORDER);

        let otpError = document.getElementById(ELEM.OTP_ERROR) as HTMLDivElement;
        this.removeCssClass(otpError, CSS.HIDE);
    }

    private hideIncorrectOtp() {
        let otpBox = document.getElementById(ELEM.OTP_FIELD_BOX) as HTMLDivElement;
        this.removeCssClass(otpBox, CSS.ERROR_BORDER);

        let otpError = document.getElementById(ELEM.OTP_ERROR) as HTMLDivElement;
        this.addCssClass(otpError, CSS.HIDE);
    }

    private hideOtpVerificationPopup() {
        if(this.resendOtpTimer != null) {
            clearTimeout(this.resendOtpTimer);
            this.resendOtpTimer = null;
        }

        this.hideResendOtpOptions();
        this.hideIncorrectOtp();
        (document.getElementById(ELEM.OTP_FIELD) as HTMLInputElement).value = '';

        let otpPopup = document.getElementById(ELEM.OTP_POPUP) as HTMLDivElement;
        this.addCssClass(otpPopup, CSS.HIDE);
    }

    private showOtpVerificationPopup() {
        let mobileNumTxt = document.getElementById(ELEM.OTP_SENT_MOBILE_TXT) as HTMLSpanElement;
        mobileNumTxt.innerHTML = this.mobileNum;

        this.resendOtpTimerValue = RESEND_OTP_TIMER_SEC;
        this.startResendOtpTimer();

        let otpPopup = document.getElementById(ELEM.OTP_POPUP) as HTMLDivElement;
        this.removeCssClass(otpPopup, CSS.HIDE);

        trackEvent('view_displayed', 'verify_with_otp_shown');
    }

    private startResendOtpTimer() {
        if(this.resendOtpTimer != null) {
          clearTimeout(this.resendOtpTimer);
        }
    
        this.resendOtpTimer = setTimeout(() => {
          this.resendOtpTimerValue--;
          let resendOtpContainer = document.getElementById(ELEM.RESEND_OTP_TIMER) as HTMLDivElement;
          if(this.resendOtpTimerValue > 0) {
            resendOtpContainer.innerHTML = 'Resend OTP in 00.' + (this.resendOtpTimerValue >= 10 ? this.resendOtpTimerValue : '0' + this.resendOtpTimerValue);
            this.startResendOtpTimer();
          } else {
            this.resendOtpTimer = null;
            this.showResendOtpOptions();
          }
        }, 1000);
    }

    private verifyOtp() {
        APIS.verifySocialRegOtp(this.mobileNum, this.otp, this.otpIdentifier)
        .then(resp => {
            if(resp.success) {
                window.location.href = resp.responsePage;
            } else {
                this.showIncorrectOtp();
                this.startResendOtpTimer();
            }
        })
        .catch(err => {
            this.showIncorrectOtp();
            this.startResendOtpTimer();
        });
    }

    private invokeResendOtp(otpOnCall:boolean = false) {
        let otpType:number = 8;
  
        APIS.resendOtp(otpType, this.transactionId, otpOnCall, this.mobileNum)
        .then(resp => {})
        .catch(err => {
          console.error("invoke resend otp err", err.message);
        });
    }

    private showInvalidNewMobile() {
        let newMobileBox = document.getElementById(ELEM.NEW_MOBILE_BOX) as HTMLDivElement;
        this.addCssClass(newMobileBox, CSS.ERROR_BORDER);

        let newMobileErr = document.getElementById(ELEM.NEW_MOBILE_ERR) as HTMLDivElement;
        this.removeCssClass(newMobileErr, CSS.HIDE);
    }

    private hideInvalidNewMobile() {
        let newMobileBox = document.getElementById(ELEM.NEW_MOBILE_BOX) as HTMLDivElement;
        this.removeCssClass(newMobileBox, CSS.ERROR_BORDER);

        let newMobileErr = document.getElementById(ELEM.NEW_MOBILE_ERR) as HTMLDivElement;
        this.addCssClass(newMobileErr, CSS.HIDE);
    }

    private hideChangeMobilePopup() {
        this.hideInvalidNewMobile();
        (document.getElementById(ELEM.NEW_MOBILE_FIELD) as HTMLInputElement).value = '';

        let changeMobilePopup = document.getElementById(ELEM.CHANGE_MOBILE_POPUP) as HTMLDivElement;
        this.addCssClass(changeMobilePopup, CSS.HIDE);
    }

    private showChangeMobilePopup() {
        let currMobile = document.getElementById(ELEM.CURRENT_MOBILE) as HTMLSpanElement;
        currMobile.innerHTML = this.mobileNum;

        let changeMobilePopup = document.getElementById(ELEM.CHANGE_MOBILE_POPUP) as HTMLDivElement;
        this.removeCssClass(changeMobilePopup, CSS.HIDE);
    }

    private showLinkAccountPopup() {
        let popup = document.getElementById(ELEM.LINK_EXISTING_USER_POPUP) as HTMLDivElement;
        this.removeCssClass(popup, CSS.HIDE);
    }

    private hideLinkAccountPopup() {
        (document.getElementById(ELEM.USERNAME_FIELD) as HTMLInputElement).value = '';
        (document.getElementById(ELEM.PWD_FIELD) as HTMLInputElement).value = '';
        (document.getElementById(ELEM.PWD_FIELD) as HTMLInputElement).type = 'password';

        let showPwdBtn = document.getElementById(ELEM.SHOW_PWD_BTN) as HTMLDivElement;
        this.removeCssClass(showPwdBtn, CSS.HIDE);

        let hidePwdBtn = document.getElementById(ELEM.HIDE_PWD_BTN) as HTMLDivElement;
        this.addCssClass(hidePwdBtn, CSS.HIDE);

        this.hideUsernameError();
        this.hidePwdError();

        let popup = document.getElementById(ELEM.LINK_EXISTING_USER_POPUP) as HTMLDivElement;
        this.addCssClass(popup, CSS.HIDE);
    }

    private hideUsernameError() {
        let usernameFieldBox = document.getElementById(ELEM.USERNAME_FIELD_BOX) as HTMLDivElement;
        this.removeCssClass(usernameFieldBox, CSS.ERROR_BORDER);

        let usernameError = document.getElementById(ELEM.USERNAME_ERR) as HTMLDivElement;
        this.addCssClass(usernameError, CSS.HIDE);
    }

    private showUsernameError(msgTxt:string = 'Enter a valid Username') {
        let usernameFieldBox = document.getElementById(ELEM.USERNAME_FIELD_BOX) as HTMLDivElement;
        this.addCssClass(usernameFieldBox, CSS.ERROR_BORDER);

        let usernameError = document.getElementById(ELEM.USERNAME_ERR) as HTMLDivElement;
        usernameError.innerHTML = msgTxt;
        this.removeCssClass(usernameError, CSS.HIDE);
    }

    private hidePwdError() {
        let pwdFieldBox = document.getElementById(ELEM.PWD_FIELD_BOX) as HTMLDivElement;
        this.removeCssClass(pwdFieldBox, CSS.ERROR_BORDER);

        let pwdError = document.getElementById(ELEM.PWD_ERROR) as HTMLDivElement;
        this.addCssClass(pwdError, CSS.HIDE);
    }

    private showPwdError() {
        let pwdFieldBox = document.getElementById(ELEM.PWD_FIELD_BOX) as HTMLDivElement;
        this.addCssClass(pwdFieldBox, CSS.ERROR_BORDER);

        let pwdError = document.getElementById(ELEM.PWD_ERROR) as HTMLDivElement;
        this.removeCssClass(pwdError, CSS.HIDE);
    }

    private linkSocialAccount() {
        APIS.linkSocialAccount(this.existingUsername, this.pwd, this.challenge)
        .then(resp => {
            if(resp.success) {
                window.location.href = resp.responsePage;
            }
            else {
                this.hideLinkAccountPopup();
                this.showErrorPopup(); 
            }
        })
        .catch(err => {
            this.hideLinkAccountPopup();
            this.showErrorPopup(); 
        });
    }

    private assignEventListeners() {
        let fbBtn = document.getElementById(ELEM.FB_BTN) as HTMLDivElement;
        fbBtn.addEventListener('click', this.onClickFBButton.bind(this));

        let submitBtn = document.getElementById(ELEM.SUBMIT_BTN) as HTMLDivElement;
        submitBtn.addEventListener('click', this.onClickSubmit.bind(this));

        let verifyOtp = document.getElementById(ELEM.VERIFY_OTP) as HTMLDivElement;
        verifyOtp.addEventListener('click', this.onClickVerifyOtp.bind(this));

        let resendOtp = document.getElementById(ELEM.RESEND_OTP_BTN) as HTMLDivElement;
        resendOtp.addEventListener('click', this.onClickResendOtp.bind(this));

        let getOtpCall = document.getElementById(ELEM.GET_OTP_ON_CALL) as HTMLDivElement;
        getOtpCall.addEventListener('click', this.onClickGetOtpOnCall.bind(this));

        let changeNum = document.getElementById(ELEM.CHANGE_MOBILE_BTN) as HTMLDivElement;
        changeNum.addEventListener('click', this.onClickChangeNumber.bind(this));

        let cancelChangeNum = document.getElementById(ELEM.CANCEL_MOBILE) as HTMLDivElement;
        cancelChangeNum.addEventListener('click', this.onClickCancelChangeMobile.bind(this));

        let saveMobile = document.getElementById(ELEM.SAVE_MOBILE) as HTMLDivElement;
        saveMobile.addEventListener('click', this.onClickSaveMobile.bind(this));

        let okBtn = document.getElementById(ELEM.ERROR_OK) as HTMLDivElement;
        okBtn.addEventListener('click', this.onClickOk.bind(this));

        let switchExistingUserBtn = document.getElementById(ELEM.SWITCH_EXISTING_USER) as HTMLDivElement;
        switchExistingUserBtn.addEventListener('click', this.onClickSwtichExistingUser.bind(this));

        let switchExistingUserBtn2 = document.getElementById(ELEM.SWITCH_EXISTING_USER_2) as HTMLDivElement;
        switchExistingUserBtn2.addEventListener('click', this.onClickSwtichExistingUser_2.bind(this));

        let switchNewUser = document.getElementById(ELEM.SWITCH_NEW_USER) as HTMLDivElement;
        switchNewUser.addEventListener('click', this.onClickSwitchNewUser.bind(this));

        let loginBtn = document.getElementById(ELEM.LOGIN_BTN) as HTMLDivElement;
        loginBtn.addEventListener('click', this.onClickLoginBtn.bind(this));

        let forgotPwdBtn = document.getElementById(ELEM.FORGOT_PWD) as HTMLDivElement;
        forgotPwdBtn.addEventListener('click', this.onClickForgotPwd.bind(this));

        let showPwdBtn = document.getElementById(ELEM.SHOW_PWD_BTN) as HTMLDivElement;
        showPwdBtn.addEventListener('click', this.onClickShowPwd.bind(this));

        let hidePwdBtn = document.getElementById(ELEM.HIDE_PWD_BTN) as HTMLDivElement;
        hidePwdBtn.addEventListener('click', this.onClickHidePwd.bind(this));
    }

    init() {
        this.assignEventListeners();

        this.getFacebookAppId(() => {
            window.fbAsyncInit = () => {
                window.FB.init({
                    appId: this.FBAppId,
                    xfbml: true,
                    status: true,
                    version: 'v3.2'
                });

                window.FB.AppEvents.logPageView();
            };

            let FBSdkScriptTag:HTMLScriptElement | null = document.getElementById(FB_SCRIPT_TAG_ID) as HTMLScriptElement;
            if(!FBSdkScriptTag) {
                let scr:HTMLScriptElement = document.createElement('script') as HTMLScriptElement;
                scr.id = FB_SCRIPT_TAG_ID;
                scr.type = 'text/javascript';
                scr.src = FB_SDK_PATH;
                document.getElementsByTagName('body')[0].appendChild(scr);
            }

            this.isFBButtonEnabled = true;
        });
    }

    onClickFBButton() {
        if(!this.isFBButtonEnabled) {
            return;
        }

        this.isFBButtonEnabled = false;

        let fbButton = document.getElementById(ELEM.FB_BTN) as HTMLDivElement;
        this.addCssClass(fbButton, CSS.FB_LOADING);

        this.initFBLogin();
        trackEvent('facebookloginbuttonclicked', 'facebookLogin');
    }

    initFBLogin() {
        let params:any = {
            'scope': 'public_profile,email',
            'return_scopes': true
        };

        window.FB.login(this.onLoginResponse.bind(this), params);
    }

    onClickSubmit() {
        trackEvent('facebookloginbuttonclicked', 'facebooklogin');
        if(this.regJourneySCode == 323) {
            this.email = (document.getElementById(ELEM.REG_EMAIL_FIELD) as HTMLInputElement).value;
            if(!validations.isEmail(this.email)) {
                this.showEmailError();
                return;
            }
            this.hideEmailError();
        }

        this.mobileNum = (document.getElementById(ELEM.MOBILE_FIELD) as HTMLInputElement).value;
        if(!validations.isMobileNumber(this.mobileNum)) {
            this.showMobileError();
            return;
        }
        this.hideMobileError();

        this.inviteCode = (document.getElementById(ELEM.INVITE_FIELD) as HTMLInputElement).value;
        if(this.inviteCode.length >= 1 && this.inviteCode.length < 8) {
            this.showInviteError();
            return;
        }
        this.hideInviteError();

        this.getOtp();
    }

    onClickVerifyOtp() {
        trackEvent('clicked', 'verify_with_otp');
        this.otp = (document.getElementById(ELEM.OTP_FIELD) as HTMLInputElement).value;

        if(!validations.isOtp(this.otp)) {
            this.showIncorrectOtp();
            return;
        }
        this.hideIncorrectOtp();

        if(this.resendOtpTimer != null) {
            clearTimeout(this.resendOtpTimer);
            this.resendOtpTimer = null;
        }

        this.verifyOtp();
    }

    onClickResendOtp() {
        trackEvent('clicked', 'resent_otp');
        this.resendOtpTimerValue = RESEND_OTP_TIMER_SEC;
        this.hideResendOtpOptions();
        this.startResendOtpTimer();
        this.invokeResendOtp();
    }

    onClickGetOtpOnCall() {
        trackEvent('clicked', 'get_otp_on_call');
        this.resendOtpTimerValue = RESEND_OTP_TIMER_SEC;
        this.hideResendOtpOptions();
        this.startResendOtpTimer();
        this.invokeResendOtp(true);
    }

    onClickChangeNumber() {
        trackEvent('clicked', 'change_number');
        this.hideOtpVerificationPopup();
        this.showChangeMobilePopup();
    }

    onClickCancelChangeMobile() {
        trackEvent('clicked', 'cancel_number_change');
        this.hideChangeMobilePopup();
        this.showOtpVerificationPopup();
    }

    onClickSaveMobile() {
        trackEvent('clicked', 'save_and_get_otp');
        let newMobile = (document.getElementById(ELEM.NEW_MOBILE_FIELD) as HTMLInputElement).value;

        if(!validations.isMobileNumber(newMobile)) {
            this.showInvalidNewMobile();
            return;
        }
        this.hideInvalidNewMobile();

        if(newMobile != this.mobileNum) {
            this.mobileNum = newMobile;
            this.getOtp();
        } else {
            this.showOtpVerificationPopup();
        }

        this.hideChangeMobilePopup();
    }

    onClickOk() {
        this.hideErrorPopup();
        this.enableFBButton();
    }

    onClickSwtichExistingUser() {
        this.hideMobileInputPopup();
        this.showLinkAccountPopup();
    }

    onClickSwtichExistingUser_2() {
        this.hideOtpVerificationPopup();
        this.showLinkAccountPopup();
    }

    onClickLoginBtn() {
        this.existingUsername = (document.getElementById(ELEM.USERNAME_FIELD) as HTMLInputElement).value;
        if(this.existingUsername.length == 0) {
            this.showUsernameError('Please enter your Email ID / Username');
            return;
        }
        else if(this.existingUsername.indexOf('@') != -1 && !validations.isEmail(this.existingUsername)) {
            this.showUsernameError('Please enter a valid Email ID');
            return;
        }
        this.hideUsernameError();

        this.pwd = (document.getElementById(ELEM.PWD_FIELD) as HTMLInputElement).value;
        if(this.pwd.length == 0) {
            this.showPwdError();
            return;
        }
        this.hidePwdError();

        this.linkSocialAccount();
    }

    onClickSwitchNewUser() {
        this.hideLinkAccountPopup();

        if(this.regJourneySCode == 19 || this.regJourneySCode == 324) {
            this.showMobileInputPopup(false);
        }
        else if(this.regJourneySCode == 323) {
            this.showMobileInputPopup(true);
        }
    }

    onClickForgotPwd() {
        window.location.href = '/player/account/forgot-password.html';
    }

    onFetchEmailComplete(resp:any) {
        if(resp && resp.email) {
            this.email = resp.email;
            this.userName = resp.name;
            this.showMobileInputPopup(false);
        }
        else {
            this.showMobileInputPopup(true);
        }
    }

    onClickShowPwd() {
        (document.getElementById(ELEM.PWD_FIELD) as HTMLInputElement).type = 'text';

        let showPwdBtn = document.getElementById(ELEM.SHOW_PWD_BTN) as HTMLDivElement;
        this.addCssClass(showPwdBtn, CSS.HIDE);

        let hidePwdBtn = document.getElementById(ELEM.HIDE_PWD_BTN) as HTMLDivElement;
        this.removeCssClass(hidePwdBtn, CSS.HIDE);
    }

    onClickHidePwd() {
        (document.getElementById(ELEM.PWD_FIELD) as HTMLInputElement).type = 'password';

        let showPwdBtn = document.getElementById(ELEM.SHOW_PWD_BTN) as HTMLDivElement;
        this.removeCssClass(showPwdBtn, CSS.HIDE);

        let hidePwdBtn = document.getElementById(ELEM.HIDE_PWD_BTN) as HTMLDivElement;
        this.addCssClass(hidePwdBtn, CSS.HIDE);
    }
}