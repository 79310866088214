import { APIS } from "../../../common/utils/api";
import { CHANNEL, trackEvent } from "../../../common/utils/common";

enum PATH {
  BASE = "/my11c/static",
  PROFILE = "/profile.html",
  LOGIN = "/login.html",
  PROFILE_INTERNATIONAL = "/profile-international.html",
}

enum ID {
  MY_PROFILE_CTA = "my-profile-cta",
  LOGOUT_CTA = "logout-cta",
}

class MoreMenuView {
  trackEvent(str1: string, str2: string) {}

  init() {
    this.assignEventListeners();
    trackEvent("view_displayed", "more_menu_loaded");
  }

  private assignEventListeners() {
    const myProfileCTA = document.getElementById(
      ID.MY_PROFILE_CTA
    ) as HTMLDivElement;
    myProfileCTA.addEventListener("click", this.onProfileClick.bind(this));

    const logoutCTA = document.getElementById(ID.LOGOUT_CTA) as HTMLDivElement;
    logoutCTA.addEventListener("click", this.onLogoutClick.bind(this));
  }

  redirectWithBase(redirect: string) {
    if (process.env.BUILD_TYPE === CHANNEL.IPL) {
      return PATH.BASE + redirect;
    }
    return redirect;
  }

  onProfileClick() {
    if (window.international) {
      window.location.pathname = this.redirectWithBase(
        PATH.PROFILE_INTERNATIONAL
      );
      return;
    }
    window.location.pathname = this.redirectWithBase(PATH.PROFILE);
  }

  onLogoutClick() {
    APIS.logoutUser()
      .then(() => {
        window.location.pathname = this.redirectWithBase(PATH.LOGIN);
        sessionStorage.removeItem("international");
      })
      .catch((err) => {
        console.log("invoke resend otp err", err.message);
      });
  }
}
export default MoreMenuView;
