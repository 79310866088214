import { validations } from "../utils/validate";
import { APIS } from "../utils/api";
import FBLogin from "../utils/FBLogin";
import { trackEvent } from "../utils/common";
import { TCLogin, TrueCallerUrlParams } from "../utils/TCLogin";

export enum ELEM {
  MOBILE_INPUT_FIELD = "mobile_input",
  EMAIL_INPUT_FIELD = "email_input",
  GET_STARTED_BTN = "getStarted",
  MOBILE_INPUT_BOX = "mobile_input_box",
  EMAIL_INPUT_BOX = "email_input_box",
  MSG_INVALID_MOBILE_DIV = "msg_invalid_mobile",
  MSG_INVALID_EMAIL_DIV = "msg_invalid_email",
  SHOW_INVITE_BTN = "show_invite_btn",
  INVITE_CODE_DIV = "invite_code_div",
  INVITE_CODE_INPUT = "invite_code_input",
  INVITE_CODE_BOX = "invite_code_box",
  MSG_INVALID_INVITE_CODE = "msg_invalid_invite_code",
  INVITE_CODE_APPLIED = "invite_code_applied",
  INVITE_CODE_SPAN = "invite_code_span",
  REMOVE_INVITE_CODE = "remove_baf_code",
  LOGIN_VIA_OTHER_BTN = "login_via_other",
  TITLE_DIV = "title_txt",
  MOBILE_PREFIX = "mobile_prefix",
  OR_SEPARATOR = "or_separator",
  FB_BTN = "fb_btn",
  INVITE_CLEAR_BTN = "baf_invite_clear_btn",
  MOBILE_INPUT_CLEAR_BTN = "mobile_input_clear_btn",
  EMAIL_INPUT_CLEAR_BTN = "email_input_clear_btn",
  INVITE_TICK = "invite_tick",
  RBD = "rbd",
};

enum CSS {
    HIDE = 'hide',
    INPUT_ERR_BORDER = 'error-border',
    INPUT_ERR_BTM_BORDER = 'error-btmborder',
};

enum MSG {
  REG_TITLE = "LOGIN/SIGNUP",
  SWITCH_TO_LOGIN_TXT = "Login using other methods",
  SWITCH_TO_REG_TXT = "Login / Register using mobile",
  MOBILE_INPUT_PLACEHOLDER_TXT = "Enter Mobile Number",
  EMAIL_INPUT_PLACEHOLDER_TXT = "Enter email address / username",
  GET_STARTED_BTN_LABEL = "GET STARTED",
  LOGIN_BTN_LABEL = "LOGIN",
  INVALID_MOBILE_MSG = "Enter a valid Mobile Number",
  INVALID_EMAIL_MSG = "Please enter a valid email address",
  INVALID_USERNAME_MSG = "Enter a valid Username",
};

const INVITE_CODE_MAX_LENGTH = 8;

const tcSdkUrlParams: TrueCallerUrlParams = {
  partnerKey: window.location.host.includes("docker", 0) ? "mswemf9ae98ab9a464e628f0d5e298932f05f" : "dYXsXaf8f6772023a4b2a826b2c834e7ff710",
  partnerName: "RummyCircle",
  skipOption: "useanothermethod",
  lang: "en",
  privacyUrl: "https://www.rummycircle.com/privacy-policy.html",
  termsUrl: "https://www.rummycircle.com/tos/terms-of-service.html",
  btnShape: "rect",
  ctaColor:"%23ffcb11",
  ctaTextColor:"%23690101"
}

const trueCallerElementSelector = '[data-truecallerLogin="enable"]' //`#${ELEM.MOBILE_INPUT_FIELD}`

class RegistrationBaseView {
  mobileNum: string = "";
  emailId: string = "";
  inviteCode: string = "";
  private isValidatingInviteCode: boolean = false;
  loginId: string = "";
  private FBLogin: FBLogin | undefined;
  isRegJourney: boolean = true;

  constructor() {}

  // PRIVATE FUNCTIONS
  addCssClass(elem: HTMLElement, className: string) {
    if (elem && !elem.classList.contains(className)) {
      elem.classList.add(className);
    }
  }

  removeCssClass(elem: HTMLElement, className: string) {
    if (elem && elem.classList.contains(className)) {
      elem.classList.remove(className);
    }
  }

  showInvalidMobileError() {
    let mobileInputBox = document.getElementById(
      ELEM.MOBILE_INPUT_BOX
    ) as HTMLDivElement;
    this.addCssClass(mobileInputBox, CSS.INPUT_ERR_BORDER);

    let errorMsg = document.getElementById(
      ELEM.MSG_INVALID_MOBILE_DIV
    ) as HTMLDivElement;
    errorMsg.innerHTML = MSG.INVALID_MOBILE_MSG;
    this.removeCssClass(errorMsg, CSS.HIDE);
  }

  showInvalidEmailError(errMsg: string = "") {
    let emailInputBox = document.getElementById(
      ELEM.EMAIL_INPUT_BOX
    ) as HTMLDivElement;
    this.addCssClass(emailInputBox, CSS.INPUT_ERR_BORDER);

    let errorMsg = document.getElementById(
      ELEM.MSG_INVALID_EMAIL_DIV
    ) as HTMLDivElement;
    errorMsg.innerHTML = errMsg.length > 0 ? errMsg : MSG.INVALID_EMAIL_MSG;
    this.removeCssClass(errorMsg, CSS.HIDE);
  }

  private hideInvalidMobileError() {
    let mobileInputBox = document.getElementById(
      ELEM.MOBILE_INPUT_BOX
    ) as HTMLDivElement;
    this.removeCssClass(mobileInputBox, CSS.INPUT_ERR_BORDER);

    let errorMsg = document.getElementById(
      ELEM.MSG_INVALID_MOBILE_DIV
    ) as HTMLDivElement;
    this.addCssClass(errorMsg, CSS.HIDE);
  }

  private hideInvalidEmailError() {
    let emailInputBox = document.getElementById(
      ELEM.EMAIL_INPUT_BOX
    ) as HTMLDivElement;
    this.removeCssClass(emailInputBox, CSS.INPUT_ERR_BORDER);

    let errorMsg = document.getElementById(
      ELEM.MSG_INVALID_EMAIL_DIV
    ) as HTMLDivElement;
    this.addCssClass(errorMsg, CSS.HIDE);
  }

  showInvalidInviteCode() {
    let inviteCodeBox = document.getElementById(
      ELEM.INVITE_CODE_BOX
    ) as HTMLDivElement;
    this.addCssClass(inviteCodeBox, CSS.INPUT_ERR_BTM_BORDER);

    let msgInvalidInviteCode = document.getElementById(
      ELEM.MSG_INVALID_INVITE_CODE
    ) as HTMLDivElement;
    this.removeCssClass(msgInvalidInviteCode, CSS.HIDE);
  }

  hideInvalidInviteCode() {
    let inviteCodeBox = document.getElementById(
      ELEM.INVITE_CODE_BOX
    ) as HTMLDivElement;
    this.removeCssClass(inviteCodeBox, CSS.INPUT_ERR_BTM_BORDER);

    let msgInvalidInviteCode = document.getElementById(
      ELEM.MSG_INVALID_INVITE_CODE
    ) as HTMLDivElement;
    this.addCssClass(msgInvalidInviteCode, CSS.HIDE);
  }

  showInviteTick() {
    this.hideInviteClearBtn();

    let inviteTick = document.getElementById(
      ELEM.INVITE_TICK
    ) as HTMLDivElement;
    this.removeCssClass(inviteTick, CSS.HIDE);
  }

  hideInviteTick() {
    let inviteTick = document.getElementById(
      ELEM.INVITE_TICK
    ) as HTMLDivElement;
    this.addCssClass(inviteTick, CSS.HIDE);
  }

  setInviteCode(inviteCode: string) {
    this.inviteCode = inviteCode;

    this.hideInvalidInviteCode();
    this.showInviteTick();
  }

  validateInviteCode(inviteCode: string) {
    if (this.isValidatingInviteCode) {
      return;
    }

    this.isValidatingInviteCode = true;

    APIS.validateInviteCode(inviteCode)
      .then((resp) => {
        if (resp.data && resp.data.status == "success") {
          this.setInviteCode(inviteCode);
          TCLogin.setRefCode(inviteCode);
        } else {
          this.showInvalidInviteCode();
        }

        this.isValidatingInviteCode = false;
      })
      .catch((err) => {
        console.log("validate invite code", err.message);
        this.isValidatingInviteCode = false;
      });
  }

  switchToLoginJourney() {
    this.isRegJourney = false;
    this.mobileNum = "";
    this.inviteCode = "";

    let titleDiv = document.getElementById(ELEM.TITLE_DIV) as HTMLDivElement;
    titleDiv.innerHTML = "";

    let inviteBtn = document.getElementById(
      ELEM.SHOW_INVITE_BTN
    ) as HTMLDivElement;
    this.addCssClass(inviteBtn, CSS.HIDE);

    this.hideInvalidInviteCode();
    this.hideInviteTick();

    let inviteDiv = document.getElementById(
      ELEM.INVITE_CODE_DIV
    ) as HTMLDivElement;
    this.addCssClass(inviteDiv, CSS.HIDE);

    let inviteInputField = document.getElementById(
      ELEM.INVITE_CODE_INPUT
    ) as HTMLInputElement;
    inviteInputField.value = "";

    let mobileInputField = document.getElementById(
      ELEM.MOBILE_INPUT_FIELD
    ) as HTMLInputElement;
    mobileInputField.value = "";
    mobileInputField.placeholder = MSG.EMAIL_INPUT_PLACEHOLDER_TXT;
    mobileInputField.maxLength = 50;
    mobileInputField.type = "email";

    let mobilePrefix = document.getElementById(
      ELEM.MOBILE_PREFIX
    ) as HTMLDivElement;
    this.addCssClass(mobilePrefix, CSS.HIDE);

    let getStartedBtn = document.getElementById(
      ELEM.GET_STARTED_BTN
    ) as HTMLDivElement;
    getStartedBtn.innerHTML = MSG.LOGIN_BTN_LABEL;

    let loginViaOtherBtn = document.getElementById(
      ELEM.LOGIN_VIA_OTHER_BTN
    ) as HTMLDivElement;
    loginViaOtherBtn.innerHTML = MSG.SWITCH_TO_REG_TXT;

    let orSeparator = document.getElementById(
      ELEM.OR_SEPARATOR
    ) as HTMLDivElement;
    this.removeCssClass(orSeparator, CSS.HIDE);

    let fbBtn = document.getElementById(ELEM.FB_BTN) as HTMLDivElement;
    this.removeCssClass(fbBtn, CSS.HIDE);

    trackEvent("view_displayed", "landingscreen/loginform");
  }

  switchToRegJourney() {
    this.isRegJourney = true;
    this.loginId = "";

    let titleDiv = document.getElementById(ELEM.TITLE_DIV) as HTMLDivElement;
    titleDiv.innerHTML = MSG.REG_TITLE;

    let inviteBtn = document.getElementById(
      ELEM.SHOW_INVITE_BTN
    ) as HTMLDivElement;
    this.removeCssClass(inviteBtn, CSS.HIDE);

    let inviteDiv = document.getElementById(
      ELEM.INVITE_CODE_DIV
    ) as HTMLDivElement;
    this.addCssClass(inviteDiv, CSS.HIDE);

    let inviteInputField = document.getElementById(
      ELEM.INVITE_CODE_INPUT
    ) as HTMLInputElement;
    inviteInputField.value = "";

    let mobileInputField = document.getElementById(
      ELEM.MOBILE_INPUT_FIELD
    ) as HTMLInputElement;
    mobileInputField.value = "";
    mobileInputField.placeholder = MSG.MOBILE_INPUT_PLACEHOLDER_TXT;
    mobileInputField.maxLength = 10;
    mobileInputField.type = "tel";

    let mobilePrefix = document.getElementById(
      ELEM.MOBILE_PREFIX
    ) as HTMLDivElement;
    this.removeCssClass(mobilePrefix, CSS.HIDE);

    let getStartedBtn = document.getElementById(
      ELEM.GET_STARTED_BTN
    ) as HTMLDivElement;
    getStartedBtn.innerHTML = MSG.GET_STARTED_BTN_LABEL;

    let loginViaOtherBtn = document.getElementById(
      ELEM.LOGIN_VIA_OTHER_BTN
    ) as HTMLDivElement;
    loginViaOtherBtn.innerHTML = MSG.SWITCH_TO_LOGIN_TXT;

    let orSeparator = document.getElementById(
      ELEM.OR_SEPARATOR
    ) as HTMLDivElement;
    this.addCssClass(orSeparator, CSS.HIDE);

    let fbBtn = document.getElementById(ELEM.FB_BTN) as HTMLDivElement;
    this.addCssClass(fbBtn, CSS.HIDE);
  }

  startRegJourney() {
    this.mobileNum = (
      document.getElementById(ELEM.MOBILE_INPUT_FIELD) as HTMLInputElement
    ).value;

    if (!validations.isMobileNumber(this.mobileNum)) {
      if (this.mobileNum.length == 0) {
        trackEvent("view_displayed", "mobile_number_error_shown", {
          "error message": "empty mobile number",
        });
      } else {
        trackEvent("view_displayed", "mobile_number_error_shown", {
          "error message": "invalid mobile number",
        });
      }
      this.showInvalidMobileError();
      return;
    }

    this.resetView();
    this.showVerifyOtpScreen(this.mobileNum, this.inviteCode);
  }

  startLoginJourney() {
    let inputVal = (
      document.getElementById(ELEM.MOBILE_INPUT_FIELD) as HTMLInputElement
    ).value;

    if (inputVal.length == 0) {
      trackEvent("view_displayed", "mobile_number_error_shown", {
        "error message": "empty email",
      });
      this.showInvalidEmailError("Please enter your Email ID / Username");
      return;
    } else if (inputVal.indexOf("@") != -1 && !validations.isEmail(inputVal)) {
      trackEvent("view_displayed", "mobile_number_error_shown", {
        "error message": "invalid email",
      });
      this.showInvalidEmailError("Please enter a valid Email ID");
      return;
    }

    this.loginId = inputVal;
    this.resetView();
    this.fetchOtpForLogin();
  }

  fetchOtpForLogin() {
    APIS.getOtpLoginStep1(this.loginId)
      .then((resp) => {
        // console.log("fetchOtpForLogin", resp);
        if (resp.success) {
          if ([602, 604].indexOf(resp.data.reasonCode) == -1) {
            this.showOtpScreenForLogin(
              this.loginId,
              resp.data.reasonCode,
              resp.data.challenge,
              resp.data.transactionId,
              resp.data.userInput
            );
          } else {
            this.showPasswordScreen(
              this.loginId,
              resp.data.reasonCode,
              resp.data.challenge,
              resp.data.userInput
            );
          }
        } else {
          if (resp.ErrorCode == 401 && resp.data.reasonCode == 3) {
            this.showInvalidEmailError(
              "Email/username does not exist. Kindly register with mobile"
            );
          } else {
            this.showInvalidEmailError(resp.ErrorMessage);
          }
        }
      })
      .catch((err) => {
        console.log("fetchOtpForLogin", err.message);
        this.showInvalidEmailError(err.message);
      });
  }

  showInviteClearBtn() {
    let inviteClearBtn = document.getElementById(
      ELEM.INVITE_CLEAR_BTN
    ) as HTMLSpanElement;
    this.removeCssClass(inviteClearBtn, CSS.HIDE);
  }

  hideInviteClearBtn() {
    let inviteClearBtn = document.getElementById(
      ELEM.INVITE_CLEAR_BTN
    ) as HTMLSpanElement;
    this.addCssClass(inviteClearBtn, CSS.HIDE);
  }

  showMobileInputClearBtn() {
    let clearBtn = document.getElementById(ELEM.MOBILE_INPUT_CLEAR_BTN) as
      | HTMLSpanElement
      | HTMLElement;
    this.removeCssClass(clearBtn, CSS.HIDE);
  }

  hideMobileInputClearBtn() {
    let clearBtn = document.getElementById(ELEM.MOBILE_INPUT_CLEAR_BTN) as
      | HTMLSpanElement
      | HTMLElement;
    this.addCssClass(clearBtn, CSS.HIDE);
  }

  showEmailInputClearBtn() {
    let clearBtn = document.getElementById(ELEM.EMAIL_INPUT_CLEAR_BTN) as
      | HTMLSpanElement
      | HTMLElement;
    this.removeCssClass(clearBtn, CSS.HIDE);
  }

  hideEmailInputClearBtn() {
    let clearBtn = document.getElementById(ELEM.EMAIL_INPUT_CLEAR_BTN) as
      | HTMLSpanElement
      | HTMLElement;
    this.addCssClass(clearBtn, CSS.HIDE);
  }

  // PUBLIC FUNCTIONS
  onClickShowInviteCodeField() {
    let inviteBtn = document.getElementById(
      ELEM.SHOW_INVITE_BTN
    ) as HTMLDivElement;
    this.addCssClass(inviteBtn, CSS.HIDE);

    let inviteDiv = document.getElementById(
      ELEM.INVITE_CODE_DIV
    ) as HTMLDivElement;
    this.removeCssClass(inviteDiv, CSS.HIDE);
  }

  onInviteCodeChange(evt: Event) {
    this.hideInviteTick();
    this.hideInvalidInviteCode();
    let inputValue = (evt.target as HTMLInputElement).value;
    let numOfChars = inputValue.length;

    if (numOfChars > 0) {
      this.showInviteClearBtn();
    } else {
      this.hideInviteClearBtn();
    }

    if (numOfChars == INVITE_CODE_MAX_LENGTH) {
      this.validateInviteCode(inputValue);
    }
  }

  onClickClearInvite() {
    this.hideInviteClearBtn();
    this.hideInvalidInviteCode();

    let inviteInputField = document.getElementById(
      ELEM.INVITE_CODE_INPUT
    ) as HTMLInputElement;
    inviteInputField.value = "";
  }

  onChangeMobileInput(evt: Event) {
    let inputValue = (evt.target as HTMLInputElement).value;
    let numOfChars = inputValue.length;

    if (numOfChars > 0) {
      this.showMobileInputClearBtn();
    } else {
      this.hideMobileInputClearBtn();
    }
  }

  onChangeEmailInput(evt: Event) {
    let inputValue = (evt.target as HTMLInputElement).value;
    let numOfChars = inputValue.length;

    if (numOfChars > 0) {
      this.showEmailInputClearBtn();
    } else {
      this.hideEmailInputClearBtn();
    }
  }

  onClickClearMobileInput() {
    this.hideMobileInputClearBtn();
    (
      document.getElementById(ELEM.MOBILE_INPUT_FIELD) as HTMLInputElement
    ).value = "";
  }

  onClickClearEmailInput() {
    this.hideEmailInputClearBtn();
    (
      document.getElementById(ELEM.EMAIL_INPUT_FIELD) as HTMLInputElement
    ).value = "";
  }

  onClickRemoveInviteCode() {
    this.inviteCode = "";

    let inviteCodeApplied = document.getElementById(
      ELEM.INVITE_CODE_APPLIED
    ) as HTMLDivElement;
    this.addCssClass(inviteCodeApplied, CSS.HIDE);

    let inviteBtn = document.getElementById(
      ELEM.SHOW_INVITE_BTN
    ) as HTMLDivElement;
    this.removeCssClass(inviteBtn, CSS.HIDE);
  }

  onClickGetStarted() {
    if (this.isRegJourney) {
      this.startRegJourney();
      trackEvent(
        "clicked",
        "landingScreen/registrationForm/registrationButton"
      );
    } else {
      this.startLoginJourney();
      trackEvent("clicked", "landingscreen/loginform/login_button");
    }
  }

  onClickLoginViaOther() {
    this.resetView();

    if (this.isRegJourney) {
      this.switchToLoginJourney();
      trackEvent("clicked", "existing_user_click_here");
    } else {
      this.switchToRegJourney();
      trackEvent("clicked", "get_started_with_mobile");
    }
  }

  onClickMobileInput() {
    if (this.isRegJourney) {
      trackEvent("clicked", "landingScreen/registrationForm/mobile");
    } else {
      trackEvent("clicked", "landingscreen/loginform/username");
    }
  }

  resetView() {
    this.hideInvalidMobileError();
  }

  resetForExternalUser() {
    this.hideInvalidEmailError();
  }

  initFbLib() {
    this.FBLogin = new FBLogin();
    this.FBLogin.init();
  }

  initTcLogin() {
    let tcElement = document.querySelector(
      trueCallerElementSelector
    ) as HTMLInputElement;
    if (tcElement) {
      const trueCallerInstance = new TCLogin(tcSdkUrlParams, tcElement);
      if (trueCallerInstance.isAndroidDevice) {
        trueCallerInstance.init();
      }
    }
  }

  invokeSendEmailExternal(email: string) {
    APIS.sendEmailExternal(email)
      .then((resp) => {
      })
      .catch((err) => {
        console.log("invoke resend otp err", err.message);
      });
  }

  showVerifyOtpScreen(mobileNum: string, inviteCode: string) {}
  showExtVerifyOtpScreen(email: string) {}

  showOtpScreenForLogin(
    loginId: string,
    reasonCode: number,
    challenge: string,
    transactionId: number,
    userInput: number
  ) {}
  showPasswordScreen(
    loginId: string,
    reasonCode: number,
    challenge: string,
    userInput: number
  ) {}
  hideVerifyOtpScreen() {}
  hidePasswordScreen() {}
}

export default RegistrationBaseView;