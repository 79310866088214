import OtpBaseView from "../../../common/views/OtpVerificationBaseView";
import { trackEvent } from "../../../common/utils/common";
import { CHANNEL } from "../../../common/utils/common";

enum ELEM {
  VERIFY_OTP_BTN = "verifyOtp",
  OTP_CLEAR_BTN = "otp_clear_btn",
  CHANGE_BTN = "changeCTA",
  OTP_INPUT_FIELD = "otpInputField",
  INCORRECT_OTP_DIV = "msg_invalid_otp",
  VERIFY_OTP_BOX = "verify_otp_box",
  RESEND_OTP_BTN = "resend_otp_btn",
  RESEND_OTP_BTN_CONT = "resend_otp_btn_container",
  OTP_SENT_TXT = "otp_sent_txt",
  RESEND_OTP_TIMER = "resend_otp_timer",
  RESEND_OTP_TIMER_DIV = "resend_otp_timer_div",
}

enum CSS {
    HIDE = 'hide',
    INPUT_ERR_BORDER = 'error-border'
};

enum MSG {
  EMPTY_OTP_MOBILE = "Please enter 6 digit OTP",
  EMPTY_OTP_EMAIL = "Please enter 8 digit OTP",
}

const RESEND_OTP_TIMER_SEC = 30;
class OtpVerificationView extends OtpBaseView {
  constructor() {
    super({ channel: CHANNEL.IPL });
  }

  trackEvent(str1: string, str2: string) {}
  init(data?: any) {
    this.assignEventListeners();
    this.setRedirect(data);
  }

  private assignEventListeners() {
    let otpInputField = document.getElementById(
      ELEM.OTP_INPUT_FIELD
    ) as HTMLInputElement;
    otpInputField.addEventListener("input", this.onChangeOtpInput.bind(this));

    let verifyOtpBtn = document.getElementById(
      ELEM.VERIFY_OTP_BTN
    ) as HTMLDivElement;
    verifyOtpBtn.addEventListener(
      "click",
      this.validateAndInitiateVerifyOtp.bind(this)
    );

    let changeMobileBtn = document.getElementById(
      ELEM.CHANGE_BTN
    ) as HTMLSpanElement;
    changeMobileBtn.addEventListener("click", this.onClickChangeCTA);

    let resendOtpBtn = document.getElementById(
      ELEM.RESEND_OTP_BTN
    ) as HTMLSpanElement;
    resendOtpBtn.addEventListener("click", this.onClickResendOtp.bind(this));

    let otpInputClearBtn = document.getElementById(
      ELEM.OTP_CLEAR_BTN
    ) as HTMLSpanElement;
    otpInputClearBtn.addEventListener(
      "click",
      this.onClickOtpClearBtn.bind(this)
    );
  }

  resetOtpScreen() {
    let otpInputField = document.getElementById(
      ELEM.OTP_INPUT_FIELD
    ) as HTMLInputElement;
    otpInputField.value = "";

    let verifyOtpBox = document.getElementById(
      ELEM.VERIFY_OTP_BOX
    ) as HTMLDivElement;
    this.removeCssClass(verifyOtpBox, CSS.INPUT_ERR_BORDER);

    let incorrectOtpContainer = document.getElementById(
      ELEM.INCORRECT_OTP_DIV
    ) as HTMLParagraphElement;
    this.addCssClass(incorrectOtpContainer, CSS.HIDE);

    let resendOtpBtnContainer = document.getElementById(
      ELEM.RESEND_OTP_BTN_CONT
    ) as HTMLSpanElement;
    this.addCssClass(resendOtpBtnContainer, CSS.HIDE);
  }

  hidePasswordLink() {}

  showPasswordLink() {}

  onClickChangeCTA = (event: Event) => {
    event.preventDefault();
    this.resetOtpScreen();
    this.setFieldDataAndTimer();
    if (this.isRegJourney) {
      this.showRegForm();
    } else this.showLoginForm();
  };

  initResendTimer() {
    let otpInputField = document.getElementById(
      ELEM.OTP_INPUT_FIELD
    ) as HTMLInputElement;
    otpInputField.value = "";
    this.hideResendOtpOptions();
    this.resendOtpTimerValue = RESEND_OTP_TIMER_SEC;
    this.startResendOtpTimer();
  }

  startResendOtpTimer() {
    if (this.resendOtpTimer != null) {
      clearTimeout(this.resendOtpTimer);
    }
    let resendOtpContainer = document.getElementById(
      ELEM.RESEND_OTP_TIMER_DIV
    ) as HTMLDivElement;
    this.resendOtpTimer = setTimeout(() => {
      this.resendOtpTimerValue--;
      if (this.resendOtpTimerValue > 0) {
        resendOtpContainer.innerHTML =
          "Resend OTP in <span style='color: black;'>" +
          this.resendOtpTimerValue +
          "s</span>";
        this.startResendOtpTimer();
      } else {
        this.resendOtpTimer = null;
        this.showResendOtpOptions();
      }
    }, 1000);
  }

  showResendOtpOptions() {
    let resendOtpTimer = document.getElementById(
      ELEM.RESEND_OTP_TIMER_DIV
    ) as HTMLDivElement;
    this.addCssClass(resendOtpTimer, CSS.HIDE);

    let resendOtpBtnContainer = document.getElementById(
      ELEM.RESEND_OTP_BTN_CONT
    ) as HTMLSpanElement;
    this.removeCssClass(resendOtpBtnContainer, CSS.HIDE);
  }

  onClickResendOtp() {
    this.initResendTimer();
    if (window.international) {
      this.invokeSendEmailExternal();
    } else {
      this.invokeResendOtp();
    }
  }

  hideResendOtpOptions() {
    let resendOtpTimer = document.getElementById(
      ELEM.RESEND_OTP_TIMER_DIV
    ) as HTMLDivElement;
    this.removeCssClass(resendOtpTimer, CSS.HIDE);

    let resendOtpBtnContainer = document.getElementById(
      ELEM.RESEND_OTP_BTN_CONT
    ) as HTMLSpanElement;
    this.addCssClass(resendOtpBtnContainer, CSS.HIDE);
  }

  onChangeOtpInput(evt: Event) {
    let inputValue = (evt.target as HTMLInputElement).value;
    let numOfChars = inputValue.length;
    this.checkOtpLength();

    if (numOfChars > 0) {
      this.showOtpClearBtn();
    } else {
      this.hideOtpClearBtn();
    }

    const OTP_MAX_LENGTH = window.international ? 8 : 6;
    if (numOfChars == OTP_MAX_LENGTH) this.onClickVerifyOtp();
  }

  checkOtpLength() {
    let otpInputField = document.getElementById(
      ELEM.OTP_INPUT_FIELD
    ) as HTMLInputElement;
    const OTP_MAX_LENGTH = window.international ? 8 : 6;
    const otpPattern = new RegExp(`^\\d{${OTP_MAX_LENGTH}}$`);

    if (otpInputField.value.length < OTP_MAX_LENGTH) {
      if (
        otpInputField.value.length > 0 &&
        !otpInputField.value.match(/\d+$/)
      ) {
        otpInputField.value = otpInputField.value.slice(
          0,
          otpInputField.value.length - 1
        );
      }
      return false;
    } else if (otpInputField.value.length > OTP_MAX_LENGTH) {
      otpInputField.value = otpInputField.value.slice(0, OTP_MAX_LENGTH);
      return otpInputField.value.match(otpPattern);
    } else {
      return otpInputField.value.match(otpPattern);
    }
  }

  validateAndInitiateVerifyOtp() {
    if (this.isVerifyingOtp) return;
    if (this.checkOtpLength()) {
      this.onClickVerifyOtp();
    } else {
      const errMsg = window.international
        ? MSG.EMPTY_OTP_EMAIL
        : MSG.EMPTY_OTP_MOBILE;
      this.showInvalidOtpError(errMsg);
      trackEvent("action_failed", "otp_verification_error", {
        "error message": "Please enter 6 digit OTP",
      });
    }
  }
}
export default OtpVerificationView;
