var validatorMessages = {
  LOGINID: {
    isEmpty: "Please enter a username",
    isShort: "Username should have atleast 4 characters",
    isLong: "Username should have atmost 12 characters",
    isInvalid: "Please enter a valid username",
  },

  PASSWORD: {
    isEmpty: "Please enter a password",
    isShort: "Password should have atleast 6 characters",
    isLong: "Password should have atmost 12 characters",
    isInvalid: "Please enter a valid password",
  },

  EMAIL: {
    isEmpty: "Please enter an Email",
    isShort: "Email should have atleast 4 characters",
    isLong: "Email should have atmost 12 characters",
    isInvalid: "Please enter a valid Email",
  },

  CAPTCHA: {
    isEmpty: "Please enter a Captcha",
  },
};

class Validations {
  constructor() {}

  isMobileNumber(val: string): boolean {
    let regexp = new RegExp(/^[6-9]\d{9}$/);
    return regexp.test(val);
  }

  isOtp(val: string, otpMaxLength: number = 6): boolean {
    let regexp = new RegExp(`^\\d{${otpMaxLength}}$`);
    return regexp.test(val);
  }

  isEmail(val: string = ""): boolean {
    if (val.length == 0 || val.length > 100) {
      return false;
    }
    if (val.indexOf("..") !== -1 || val.startsWith(".") || val.endsWith(".")) {
      return false;
    }
    let regexp = new RegExp(
      /^([a-zA-Z0-9_][a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9_-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    );
    return regexp.test(val);
  }

  getErrorMsgForEmail(val: string = ""): string {
    var validatorMessage = validatorMessages.EMAIL;
    if (!val) return validatorMessage.isEmpty;
    else if (
      !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
        val
      )
    )
      return validatorMessage.isInvalid;
    else return "";
  }
}

export const validations = new Validations();
