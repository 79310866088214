export const validateTeamName = (firstName: string) => {
  if (firstName.trim() === "") {
    return "This field cannot be blank. Please enter your team name.";
  }
  return "";
};
export const validateFirstName = (firstName: string) => {
  if (firstName.trim() === "" || firstName.trim().length < 2) {
    return "This field cannot be blank. Please enter your full name.";
  }
  return "";
};
export const validateLastName = (firstName: string) => {
  if (firstName.trim() === "") {
    return "This field cannot be blank. Please enter your Last name.";
  }
  return "";
};

export const isMobCorrect = (mobileNumber: string) => {
  const mobileNoRexp = /^[6789]\d{9}$/;
  if (mobileNoRexp.test(mobileNumber)) {
    return "";
  }
  return "Enter Valid Phone number";
};

export function getDateInUI(date: string) {
  try {
    if (!date) return "";
    let dbDate = new Date(date);
    return dbDate.toLocaleString("en-in", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  } catch (error) {}
  return "";
}

export function getDateFromServer(date: string) {
  try {
    if (!date) return "";
    let split = date.split("/");
    return `${split[2]}-${split[1]}-${split[0]}`;
  } catch (error) {}
  return "";
}

export const validateDateFormat = (date: any) => {
  var regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  //Check whether valid dd/MM/yyyy Date Format.
  if (regex.test(date)) {
    let dateArr = date.split("/");
    let enteredDate = new Date(
      dateArr[2],
      dateArr[1] - 1,
      dateArr[0]
    ).getTime();
    let currentDate = new Date().getTime();
    if (enteredDate > currentDate) {
      return "Date of birth entered is not valid. Please enter a valid date.";
    }
    let age = Math.floor(
      (currentDate - enteredDate) / (1000 * 60 * 60 * 24 * 365.25)
    );
    if (age < 18) {
      return "You cannot proceed if your age is below 18";
    }
    return "";
  } else if (date === "") {
    return "This field cannot be blank. Please enter your Date of birth.";
  } else {
    return "Date of birth entered is not valid. Please enter a valid date.";
  }
};

export const validateAddress = (addr: string) => {
  if (addr == "") {
    return "This field cannot be blank. Please enter your address";
  } else {
    addr = addr.trim();
    const iChars = '`!~@$%^*+=_[];{}|"<>?';
    let errCount = 0;
    let spaceCount = 0;
    for (let i = 0; i < addr.length; i++) {
      if (iChars.indexOf(addr.charAt(i)) == -1) {
        spaceCount++;
      } else if (iChars.indexOf(addr.charAt(i)) != -1) {
        errCount++;
      }
    }

    if (addr.length < 3) {
      return "Use at least 3 chars for the address field.";
    }
    if (errCount > 0) {
      return "Address can have alphabets (A to Z and a to z), numbers (0 to 9), space, comma, period (.), slash ( or /), hyphen (-), colon (:), hash (#), Amp (&), apostrophe (') and braces () only.";
    }

    return "";
  }
};

export const validatePinCode = (
  pincode: string,
  invalidPostal: boolean = false
) => {
  if (pincode == "") {
    return "This filed cannot be blank. Please enter your pincode";
  } else if (pincode.length < 6 || invalidPostal) {
    return "Please enter valid Pin Code";
  } else {
    return "";
  }
};

export const validateState = (value: string) => {
  if (value == "") {
    return "Please enter valid Pin Code";
  }
  return "";
};
export const validateCity = (value: string) => {
  if (value == "") {
    return "This filed cannot be blank. Please enter your city";
  }
  return "";
};

function countContain(strPassword: string, strCheck: string) {
  // Declare variables
  let nCount = 0;

  for (let i = 0; i < strPassword.length; i++) {
    if (strCheck.indexOf(strPassword.charAt(i)) > -1) {
      nCount++;
    }
  }

  return nCount;
}
export const validatePassword = (value: string) => {
  if (value.trim() === "") {
    return "This filed cannot be blank. Please enter password";
  }
  const m_strUpperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const m_strNumber = "0123456789";
  const m_strCharacters = "!@#$%^&*?_~";

  const charCount = countContain(value, m_strUpperCase);
  const numberCount = countContain(value, m_strNumber);
  const specialCharCount = countContain(value, m_strCharacters);
  if (value.length < 8) {
    return "Use atleast 8 chars. Don't use your email / username / DOB.";
  }
  if (charCount < 1 || numberCount < 1 || specialCharCount < 1) {
    return "Your password must be at least 8 characters long and must contain at least one alphabet(A-Z,a-z), one digit(0-9) and one special character (#,*,&,$,% etc).";
  }
  return "";
};
export const validateConfirmPassword = (value: string, password: string) => {
  if (value.trim() === "") {
    return "This filed cannot be blank. Please enter password";
  }
  if (value !== password) {
    return "Passwords don't match. Please enter same password in both fields & try again.";
  }
  return "";
};

export const validateEmail = (value: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return "";
  }
  return "You have entered an invalid email address!";
};
