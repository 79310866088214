import { CHANNEL, deviceId, deviceName, randomHex } from "./common";

enum URLS {
  getOtpRegStep1 = "/api/fl/auth/v3/getOtp",
  getOtpRegStep2 = "/api/fl/auth/tokenize/v1/getOtp", // using in fsl
  verifyOtpRegStep2 = "/api/fl/auth/v3/authenticate",
  verifyOtpRegStep3 = "/api/fl/auth/tokenize/v1/authenticate", // using in fsl
  verifyExternalOtpReg = "/api/fl/auth/tokenize/v1/external/verifyEmailOtp", // using in fsl
  validateInviteCode = "/api/fl/baf/v1/validateInviteCode",
  resendOtp = "/api/fl/account/v1/sendOtp",
  resendOtp2 = "/api/fl/auth/tokenize/v1/sendOtp", // using in fsl
  sendEmailExternal = "/api/fl/auth/tokenize/v1/external/sendEmail", // using in fsl
  getOtpLoginStep1 = "/api/fl/auth/v2/getLogin",
  verifyLoginStep2 = "/api/fl/auth/v2/login",
  socialLogin = "/api/fl/auth/v3/social/login",
  getOtpSocialRegStep1 = "/api/fl/auth/v3/social/getOtp",
  verifyOtpSocialRegStep2 = "/api/fl/auth/v3/social/register",
  linkSocialAccount = "/social/linkFBwithRCaccount",
  verify2FAOtp = "/signup/verify",
  getCaptcha = "/api/captcha/v1/getCaptcha",
  tcUserRegister = "/api/fl/auth/v3/tcAuthenticate",
  tcPolling = "/api/pp/poll/v1/truecaller/",
  checkLogin2 = "/api/fl/checklogin/v1/checkloginwithtoken", // using in fsl
  logoutUser = "/api/fl/account/tokenize/v1/logOutUser", // using in fsl
}

//using in fsl
enum PROFILE_API_PATHS {
  GET_POSTAL_CODE = "/api/fl/account/tokenize/v1/getPostalCodeDetails",
  UPDATE_ADDRESS = "/api/fl/account/tokenize/v1/saveAddressDetails",
  GET_ADDRESS_PAGE_DETAILS = "/api/fl/account/tokenize/v1/getAddressPageDetails",
  SEND_USER_DELETE_EMAIL = "/api/fl/account/tokenize/v1/sendUserDeleteEmail",
  GET_INTERNATIONAL_USER_DETAILS = "/api/fl/account/tokenize/v1/external/getUserProfileDetails",
  UPDATE_INTERNATIONAL_USER_DETAILS = "/api/fl/account/tokenize/v1/external/updateUserProfile",
  DELETE_INTERNATIONAL_USER = "/api/fl/account/tokenize/v1/external/deleteUserAccount",
}

enum ENV {
  DEV = "development",
  PROD = "production",
}

const LoginResponse = {
  [CHANNEL.IPL]: 2,
};

class API {
  private BASE_URL;
  constructor() {
    if (process.env.NODE_ENV === ENV.DEV) {
      this.BASE_URL = `https://docker-3bp36s.fickle.my11circle.com`;
    } else {
      if (process.env.BUILD_TYPE === CHANNEL.IPL) {
        this.BASE_URL = "/my11c";
      } else {
        this.BASE_URL = "";
      }
    }
  }

  async getOtpRegStep1(
    mobileNo: string,
    inviteCode: string = ""
  ): Promise<any> {
    let reqBody = {
      mobile: mobileNo,
      deviceId: deviceId(),
      deviceName: "",
      refCode: inviteCode,
      isPlaycircle: false,
    };

    const response = await fetch(this.BASE_URL + URLS.getOtpRegStep1, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  async getOtpRegStep2(
    mobileNo: string,
    inviteCode: string = ""
  ): Promise<any> {
    let reqBody = {
      mobile: mobileNo,
      deviceId: "BCCI" + randomHex(),
      deviceName: "",
      refCode: inviteCode,
      isPlaycircle: false,
      loginRegSource: LoginResponse[CHANNEL.IPL],
    };

    const response = await fetch(this.BASE_URL + URLS.getOtpRegStep2, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  async verifyOtpRegStep2(
    mobileNo: string,
    otp: string,
    identifier: string
  ): Promise<any> {
    let reqBody = {
      mobile: mobileNo,
      otp: otp,
      uniqueIdentifier: identifier,
    };

    const response = await fetch(this.BASE_URL + URLS.verifyOtpRegStep2, {
      method: "POST",
      // mode: "same-origin",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  async verifyOtpRegStep3(
    mobileNo: string,
    otp: string,
    identifier: string
  ): Promise<any> {
    let reqBody = {
      mobile: mobileNo,
      otp: otp,
      uniqueIdentifier: identifier,
      loginRegSource: LoginResponse[CHANNEL.IPL],
    };

    const response = await fetch(this.BASE_URL + URLS.verifyOtpRegStep3, {
      method: "POST",
      // mode: "same-origin",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  async verifyExternalOtpReg(
    email: string,
    otp: string,
    identifier: string
  ): Promise<any> {
    let reqBody = {
      email: email,
      otp: otp,
      session: identifier,
    };

    const response = await fetch(this.BASE_URL + URLS.verifyExternalOtpReg, {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  async verifyOtpRegStep2ForLogin(
    mobileNo: string,
    otp: string,
    challenge: string
  ): Promise<any> {
    let reqBody = {
      mobile: mobileNo,
      otp: otp,
      isLogin: true,
      reasonCode: 603,
      challenge: challenge,
    };

    const response = await fetch(this.BASE_URL + URLS.verifyOtpRegStep2, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  async verifyOtpRegStep2ForLogin2(
    mobileNo: string,
    otp: string,
    challenge: string
  ): Promise<any> {
    let reqBody = {
      mobile: mobileNo,
      otp: otp,
      isLogin: true,
      reasonCode: 603,
      challenge: challenge,
      loginRegSource: LoginResponse[CHANNEL.IPL],
    };

    const response = await fetch(this.BASE_URL + URLS.verifyOtpRegStep3, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  async validateInviteCode(inviteCode: string): Promise<any> {
    let reqBody = {
      inviteCode: inviteCode,
    };

    const response = await fetch(this.BASE_URL + URLS.validateInviteCode, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async resendOtp(
    otpType: number,
    transactionId: number,
    otpOnCall: boolean = false,
    mobile: string = ""
  ): Promise<any> {
    let reqBody = {
      otpOnCall: otpOnCall,
      otpType: otpType,
      transactionId: transactionId,
      mobile: mobile,
    };

    // reg flow {"journeyId":"RC","otpType":8,"mobile":"7070123402","transactionId":1640273266511}
    // login flow {"mobile":"","otpOnCall":false,"otpType":6,"transactionId":1640273636543}
    const response = await fetch(this.BASE_URL + URLS.resendOtp, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async resendOtp2(
    otpType: number,
    transactionId: number,
    otpOnCall: boolean = false,
    mobile: string = ""
  ): Promise<any> {
    let reqBody = {
      otpOnCall: otpOnCall,
      otpType: otpType,
      transactionId: transactionId,
      mobile: mobile,
      loginRegSource: LoginResponse[CHANNEL.IPL],
    };

    // reg flow {"journeyId":"RC","otpType":8,"mobile":"7070123402","transactionId":1640273266511}
    // login flow {"mobile":"","otpOnCall":false,"otpType":6,"transactionId":1640273636543}
    const response = await fetch(this.BASE_URL + URLS.resendOtp2, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async sendEmailExternal(
    email: string = ""
  ): Promise<any> {
    let reqBody = {
      email: email,
    };

    // reg flow {email: 'exampleMail@gmail.com'}
    const response = await fetch(this.BASE_URL + URLS.sendEmailExternal, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async getOtpLoginStep1(loginId: string): Promise<any> {
    let reqBody = {
      loginid: loginId,
      deviceId: deviceId(),
    };

    const response = await fetch(this.BASE_URL + URLS.getOtpLoginStep1, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async verifyOtpLoginStep2(
    loginId: string,
    otp: string,
    challenge: string,
    reasonCode: number
  ): Promise<any> {
    let reqBody = {
      loginid: loginId,
      otp: otp,
      reasonCode: reasonCode,
      challenge: challenge,
      deviceId: deviceId(),
      deviceName: deviceName(),
    };

    const response = await fetch(this.BASE_URL + URLS.verifyLoginStep2, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async verifyPassword(
    loginId: string,
    pwd: string,
    challenge: string,
    reasonCode: number = 1
  ): Promise<any> {
    let reqBody = {
      loginid: loginId,
      passwd: pwd,
      reasonCode: reasonCode,
      challenge: challenge,
      deviceId: deviceId(),
      deviceName: deviceName(),
    };

    const response = await fetch(this.BASE_URL + URLS.verifyLoginStep2, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async initSocialLogin(
    socialType: string,
    social_userid: string,
    auth_token: string,
    geoLocState: string = ""
  ): Promise<any> {
    let reqBody = {
      value: {
        socialType: socialType,
        social_userid: social_userid,
        auth_token: auth_token,
        isMobileMandatory: true,
      },
      deviceId: deviceId(),
      geoLocState: geoLocState,
    };

    const response = await fetch(this.BASE_URL + URLS.socialLogin, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async getSocialRegOtp(
    mobileNum: string,
    challenge: string,
    email: string,
    inviteCode: string = ""
  ): Promise<any> {
    let reqBody = {
      mobile: mobileNum,
      deviceId: deviceId(),
      refCode: inviteCode,
      challenge: challenge,
      email: email,
    };

    const response = await fetch(this.BASE_URL + URLS.getOtpSocialRegStep1, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async verifySocialRegOtp(
    mobileNum: string,
    otp: string,
    identifier: string
  ): Promise<any> {
    let reqBody = {
      mobile: mobileNum,
      uniqueIdentifier: identifier,
      otp: otp,
    };

    const response = await fetch(this.BASE_URL + URLS.verifyOtpSocialRegStep2, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async linkSocialAccount(
    username: string,
    pwd: string,
    challenge: string
  ): Promise<any> {
    let reqBody = {
      usernameoremail: username,
      passwrd: pwd,
      challenge: challenge,
      deviceId: deviceId(),
    };

    const response = await fetch(this.BASE_URL + URLS.linkSocialAccount, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async verify2FAOtp(authType: number, otp: string): Promise<any> {
    let reqBody = {
      verificationText: otp,
      authType: authType,
      deviceId: deviceId(),
    };

    const response = await fetch(this.BASE_URL + URLS.verify2FAOtp, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async getCaptcha() {
    const response = await fetch(this.BASE_URL + URLS.getCaptcha, {
      method: "POST",
      credentials: "same-origin",
      cache: "no-cache",
    });
    return await response.text();
  }

  async checkLogin() {
    const response = await fetch(this.BASE_URL + "/api/signup/v1/checkLogin", {
      method: "POST",
      credentials: "include",
    });
    return await response.json();
  }

  async checkLogin2() {
    const response = await fetch(this.BASE_URL + URLS.checkLogin2, {
      method: "POST",
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  }

  async logoutUser() {
    const response = await fetch(this.BASE_URL + URLS.logoutUser, {
      method: "POST",
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  }

  async getTruecallerStatus(reqId: string) {
    const response = await fetch(this.BASE_URL + URLS.tcPolling + reqId, {
      method: "GET",
      cache: "no-cache",
    });

    return await response.json();
  }

  async registerTcUser(requestId: string, inviteCode: string) {
    let reqBody = {
      requestId,
      deviceId: deviceId(),
      deviceName: deviceName(),
      refCode: inviteCode,
    };

    const response = await fetch(this.BASE_URL + URLS.tcUserRegister, {
      method: "POST",
      // mode: 'same-origin',
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(reqBody),
    });

    return await response.json();
  }

  async resetPwd(data: any) {
    const response = await fetch(
      this.BASE_URL + "/api/myAccountApi/v1/sendResetPassword",
      {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }

  async getPostalCode(data: any) {
    // return {
    //   officeName: "Amgola S.O",
    //   pinCode: 842001,
    //   officeType: "S.O",
    //   deliveryStatus: "Non-Delivery",
    //   divisionName: "Muzaffarpur",
    //   regionName: "Muzaffarpur",
    //   circleName: "Bihar",
    //   taluk: "NA",
    //   districtName: "Muzaffarpur",
    //   stateName: "Bihar",
    // };
    const response = await fetch(
      this.BASE_URL + PROFILE_API_PATHS.GET_POSTAL_CODE,
      {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }

  async getUserDetails(data: any) {
    // return {
    //   kycStatus: 1,
    //   lockedPlayer: false,
    //   loginId: "steelprmp04k",
    //   addrLine1: "adasdasdasd",
    //   pinCode: "842001",
    //   state: "Bihar",
    //   dob: "1995-06-23",
    //   mobile: 8447149834,
    //   isStrongPassword: true,
    //   addrLine2: "",
    //   emailConfirmation: 0,
    //   email: "pshabbir36@gmail.com",
    //   firstName: "Shabbir",
    //   lastName: "Hussain",
    //   city: "Muz",
    // };
    const response = await fetch(
      this.BASE_URL + PROFILE_API_PATHS.GET_ADDRESS_PAGE_DETAILS,
      {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }

  async getInternationalUserDetails(data: any) {
    // return {
    // username: "c1734dea-f081-7075-6412-f2e779fc2132",
    // email: "loteci2377@pariag.com",
    // emailVerified: true,
    // dateOfBirth: "2004-05-04",
    // fullName: "kfkjgh dfkg"
    // }
    const response = await fetch(
      this.BASE_URL + PROFILE_API_PATHS.GET_INTERNATIONAL_USER_DETAILS,
      {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }

  async saveAddressDetails(data: any) {
    // return {
    //   ErrorCode: 98,
    //   ErrorMessage: "Address already saved",
    // };
    const response = await fetch(
      this.BASE_URL + PROFILE_API_PATHS.UPDATE_ADDRESS,
      {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }

  async saveInternationalUserDetails(data: any) {
    // return {
    //   errorCode: 400,
    //   errorMessage: "Access Token has expired"
    // };
    const response = await fetch(
      this.BASE_URL + PROFILE_API_PATHS.UPDATE_INTERNATIONAL_USER_DETAILS,
      {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }

  async sendUserDeleteEmail(data: any) {
    // return {
    //   success: true,
    // };
    const response = await fetch(
      this.BASE_URL + PROFILE_API_PATHS.SEND_USER_DELETE_EMAIL,
      {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }

  async triggerInternationalUserDeletion(data: any) {
    // return {
    //   success: true,
    // };
    const response = await fetch(
      this.BASE_URL + PROFILE_API_PATHS.DELETE_INTERNATIONAL_USER,
      {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }
}

export const APIS = new API();
