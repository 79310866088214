import { ELEM } from './../views/RegistrationBaseView';
import { APIS } from './api';
import { getChannelId, trackEvent } from "../utils/common";
/**
 * TrueCaller SDK integration for web
 */

const TC_SDK_PATH_PREFIX = `truecallersdk://truesdk/web_verify?type=btmsheet`;

enum URLS{
    RBD_BLOCK = "/rcspa/rbd/",
};



type SdkUrlParams = {
    requestNonce?:string
    partnerKey:string
    partnerName:string
    lang?:string
    privacyUrl?:string
    termsUrl?:string
    loginPrefix?:string
    loginSuffix?:string
    ctaPrefix?:string
    ctaColor?:string
    ctaTextColor?:string
    btnShape?:string
    skipOption?:string
}

enum InputFieldAttributes {
    tel="tel",
    email="email",
    text="text",
    search="search"
}

const timeoutToCheckFocus = 600;
const apiDetails = {
    pollTime: 2000,
    maxCalls: 8
}

const progressInfoContainer = "#tc-progress-container"
const progressInfoSelector =".tc-progress-info"
const tcInvokeDataSelector="truecaller-invoke"

const _tcSkip = "_tcSkip"
const _tcRetry = "_tcRetry"
const loaderUrl = "/fusionassets/img/tc_loader.gif"
const brokenUrl = "/fusionassets/img/tc_broken.svg"

const API_STATUS = {
    USER_NOT_EXIST: "USER_NOT_EXIST",
    USER_EXIST: "USER_EXIST",
    USER_REJECTED: "USER_REJECTED",
    INVALID_RES: "INVALID_RES"
}


export type TrueCallerUrlParams = Omit<SdkUrlParams, "requestNonce">

export class TCLogin implements TCLogin {

    private requestNonce: string | null;
    private tcUrlParams: TrueCallerUrlParams & Pick<SdkUrlParams, "requestNonce">;
    private tcElementRef: HTMLElement;
    private inputFieldRef: HTMLInputElement | null
    
    public isAndroidDevice = /(android)/i.test(navigator.userAgent);
    private pollingInProgress:boolean;

    constructor(tcUrlParams: TrueCallerUrlParams, tcElement: HTMLInputElement) {
        this.tcUrlParams = tcUrlParams;
        this.requestNonce = null;
        this.tcElementRef = tcElement;
        this.pollingInProgress = false;
        this.inputFieldRef = tcElement?.querySelector(`#${ELEM.MOBILE_INPUT_FIELD}`) as HTMLInputElement | null
    }

    static refCode:string = "";
    static setRefCode (refCode:string) {
        TCLogin.refCode = refCode;
    }

    init() {
        this.tcLoaderContent();
        this.listentoInputFoucus();
    }

    listentoInputFoucus() {
        this.tcElementRef.addEventListener("click", this.onTcElementClick);
        if (this.inputFieldRef) {
            this.inputFieldRef.disabled = true;
        }
    }

    onTcElementClick = (event: FocusEvent) => {
        let element = event.target as HTMLInputElement | HTMLButtonElement;
        if (this.inputFieldRef) {
            this.inputFieldRef.disabled = false;
        }
        if (element.id === ELEM.MOBILE_INPUT_FIELD || element.id == ELEM.GET_STARTED_BTN || this.isTruecallerInvokeButton(element)) {
            this.tcElementRef.removeEventListener("click", this.onTcElementClick);
            this.invokeTcVerification();
        }
    }

    isTruecallerInvokeButton(element: HTMLElement) {
        let datasetVal =  element.dataset && element.dataset.button;
        return datasetVal === tcInvokeDataSelector
    }

    invokeTcVerification() {
        this.requestNonce = this.getRequestId();
        let tcSdkUrl = TC_SDK_PATH_PREFIX;
        this.tcUrlParams.requestNonce = this.requestNonce;
        Object.entries(this.tcUrlParams).forEach(([key, val]) => {
            tcSdkUrl += `&${key}=${val}`;
        });
        window.location = tcSdkUrl as any;
        this.detectTcExist();
    }


    detectTcExist() {
        setTimeout(() => {
            if (document.hasFocus()) {
                // Truecaller not found 
                this.focusInputField();
            } else {
                trackEvent("gtm.shown", "true_caller_popup_shown", null, { request_id: this.requestNonce })
                this.toggleLoader();
                this.pollingInProgress = true;
                this.startPolling();
                const loaderImg = document.querySelector(".tc-progress img") as HTMLImageElement;
                loaderImg.src = loaderUrl;
            }
        }, timeoutToCheckFocus);
    }


    handleErrorCase() {
        const psInfo = document.querySelector(progressInfoSelector);
        psInfo?.classList.remove("hide");
        let img = psInfo?.parentElement?.firstElementChild as HTMLImageElement
        if (img) {
            img.src = brokenUrl;
        }
        psInfo?.addEventListener("click", this.handleProgressActions)
    }

    handleProgressActions = (event:Event) => {
        const btnRef = event.target as HTMLButtonElement;
        if (!btnRef) return;

        let psInfo = document.querySelector(progressInfoSelector)
        psInfo?.classList.add("hide");
        psInfo?.removeEventListener("click", this.handleProgressActions);
        this.toggleLoader();
        if(btnRef.id === _tcSkip) {
            this.tcElementRef.focus();
        }else if(btnRef.id === _tcRetry) {
            this.invokeTcVerification();
        }
    }

    toggleLoader() {
        document.querySelector(progressInfoContainer)?.classList.toggle("hide");
    }

    tcLoaderContent() {
        const div = document.createElement("div");
        div.id = "tc-progress-container"
        div.className = "hide"
        div.innerHTML = `<div class="tc-progress-content">
                <div class="tc-progress">
                    <img src="${loaderUrl}">
                    <div class="tc-progress-info hide"> 
                         <p>Seems like something is broken, do not worry, please click on ‘Try again’ to login</p>
                         <div class="tc-actions"> 
                             <button id="${_tcSkip}">Cancel</button>
                            <button id="${_tcRetry}">Try Again</button>
                         </div>
                    </div>
                </div>
            </div>`
        document.body?.appendChild(div);
    }

    async startPolling(count= apiDetails.maxCalls) {
        try {
            if (!this.pollingInProgress) return;

            if(!count) {
                throw new Error("Call Exhausted!!");
            }
            await new Promise(resolve => setTimeout(resolve, apiDetails.pollTime));
            const tcData = await APIS.getTruecallerStatus(this.requestNonce || "");
            if (tcData.status === API_STATUS.USER_EXIST) {
                trackEvent("gtm.click", "tc_click_on_use_phone_number", null, { request_id: this.requestNonce })
                await this.registerUser();
                this.stopPolling();
            } else if (tcData.status === API_STATUS.USER_REJECTED) {
                trackEvent("action_failed", "tc_user_rejected",null, { request_id: this.requestNonce })
                this.stopPolling();
                this.focusInputField();
            } else if (tcData.status === API_STATUS.USER_NOT_EXIST) {
                this.startPolling(count - 1);
            }else {
                throw new Error(tcData)
            }
        }catch(err) {
            this.handleErrorCase()
            this.tcElementRef.removeEventListener("click", this.onTcElementClick);
        }
    }

    async registerUser() {
        let channelId:number = getChannelId();
        const response = await APIS.registerTcUser(this.requestNonce || "", TCLogin.refCode);
        let rbd = {
            responsePage : response.responsePage,
            userId : response.data?.userId
          }
        setTimeout(() => {
        if(channelId === 2 && document.getElementById(ELEM.RBD) && response.data?.register)
        {
            sessionStorage.setItem(ELEM.RBD, JSON.stringify(rbd));
            window.location.pathname = URLS.RBD_BLOCK;
        }
        else
        {
            if(response.responsePage != undefined)
                window.location.href = response.responsePage;
        }
        });
    }

    focusInputField() {
        setTimeout(() => {
            document.getElementById(ELEM.MOBILE_INPUT_FIELD)?.focus();
        }, 100)
    }

    stopPolling() {
        this.pollingInProgress = false;
        document.querySelector(progressInfoContainer)?.classList.add("hide");
    }


    getRequestId(prefix = '') {
        return `${prefix}${this.uuidv4()}`;
    }

    uuidv4() {
        if (!window.crypto) return Date.now().toString(36) + Math.random().toString(36).substring(2); 
         return (String(1e5) + String(1e3) + String(4e3) + String(8e4)).replace(/[018]/g, c =>
             (Number(c) ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> Number(c) / 4).toString(16)
         );
    }    
}


