const UAParser = require('ua-parser-js');
declare global {
    interface Window {
        trackThis: Function;
    }
}

export function randomHex(placeholder:any = null):string {
    if (placeholder) {
        return (placeholder ^ Math.random() * 16 >> placeholder / 4).toString(16);
    }
    return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, randomHex);
};

function deviceId():string {
    let deviceId:string | null = localStorage.getItem("deviceID");
    if (!deviceId) {
        deviceId = randomHex();
        localStorage.setItem("deviceID", deviceId);
    }
    return deviceId;
}

 /*
Utility: Function that loads all scripts and css and appends them to the body.
You can find these scripts/ css sheets by the class 'dynamic-css-js'
You can use this when some overlay needs a specific stylesheet
Do not modify, this was built for Social Baf
*/
function asyncScriptLoader(scripts:string[], callback:Function, addClass:string = 'dynamic-css-js') {
    let count = scripts.length;
    for (let i:number = 0; i < scripts.length; i++) {
        if (scripts[i].indexOf(".css") !== -1) {
            //Its a stylesheet
            let link:HTMLLinkElement = document.createElement('link') as HTMLLinkElement;
            link.type = 'text/css';
            link.className = addClass;
            link.rel = 'stylesheet';
            link.href = scripts[i];
            document.getElementsByTagName('head')[0].appendChild(link);

            let img = document.createElement('img');
            img.onerror = () => {
                count -= 1;
                if (count !== 0) {
                    return;
                }

                if (callback) callback();
            }
            img.src = scripts[i];
        } else {
            let scr:HTMLScriptElement = document.createElement('script') as HTMLScriptElement;
            scr.type = 'text/javascript';
            scr.async = true;
            scr.className = addClass;
            scr.onload = () => {
                count -= 1;
                if (count !== 0) {
                    return;
                }

                if (callback) callback();
            }
            scr.src = scripts[i];
            document.getElementsByTagName('body')[0].appendChild(scr);
        }
    }
}

function getChannelId():number {
    let channelId:number = 0;
    let ua = navigator.userAgent;
    if (ua.indexOf('rcchannelid') > 0 || ua.indexOf('RCAndroid') > 0) {
        channelId = 3;
    } else if (ua.indexOf('RCiOS') > 0) {
        channelId = 4;
    } else if (ua.indexOf('FTAndroid') > 0) {
        channelId = 2003;
    } else if (ua.indexOf('FTiOS') > 0) {
        channelId = 2004;
    } else if (ua.indexOf('PCAndroid') > 0) {
        channelId = 1000003
    } else if (ua.indexOf('PCiOS') > 0) {
        channelId = 1000004
    } else if (ua.indexOf('PCRCAndroid') > 0) {
        channelId = 1001003
    } else if (ua.indexOf('PCRCiOS') > 0) {
        channelId = 1001004
    } else if (ua.indexOf('PCFTAndroid') > 0) {
        channelId = 1002003
    } else if (ua.indexOf('PCFTiOS') > 0) {
        channelId = 1002004
    } else if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune|Nokia|UCWEB/.test(ua)) {
        //Device is mobile!
        channelId = 2;
    } else {
        //Device is NOT mobile
        channelId = 1;
    }

    return channelId;
}

function isFantasyApp(channelId:number) {
    if (!channelId) {
        channelId = getChannelId();
    }

    switch (channelId) {
        case 2003:
        case 2004:
        case 1002003:
        case 1002004:
            return true;
    }

    return false;
}

function deviceName() {
    let deviceName:string = 'Desktop';
    let deviceInfo:any = UAParser(navigator.userAgent);
    if(deviceInfo && deviceInfo.device && deviceInfo.device.model) {
        deviceName = deviceInfo.device.model;
    }
    else if(deviceInfo && deviceInfo.os && deviceInfo.os.name) {
        deviceName = deviceInfo.os.name;
    }

    return deviceName;
}

function trackEvent(event:string, id:string, metadata:any = null, metadata_extra:any = null) {
    // window.trackThis(event, id, metadata, metadata_extra);
}

const CHANNEL = {
  IPL: "IPL",
};

/**
 * Utility for safely interacting with localStorage
 */
function isLocalStorageAvailable(): boolean {
  try {
    const test = '__test__';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    console.error('localStorage not available');
    return false;
  }
}

function getLocalStorageItem(key: string): string | null {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(key);
  }
  return null;
}

function setLocalStorageItem(key: string, value: string): void {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, value);
  }
}

function removeLocalStorageItem(key: string): void {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  }
}

function clearLocalStorage(): void {
  if (isLocalStorageAvailable()) {
    localStorage.clear();
  }
}

export {
  deviceId,
  asyncScriptLoader,
  getChannelId,
  isFantasyApp,
  trackEvent,
  deviceName,
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  clearLocalStorage,
  CHANNEL,
};