import RegistrationView from "./views/RegistrationView";
import OtpVerificationView from "./views/OtpVerificationView";
import { CHANNEL, trackEvent } from "../../common/utils/common";
import { APIS } from "../../common/utils/api";
import MyProfileView from "./views/MyProfileView";
import MyProfileInternationalView from "./views/MyProfileInternationalView";
import MoreMenuView from "./views/MoreMenuView";

import ExternalUserRegView from "./views/ExternalUserRegView";
const queryString = require("query-string");
declare global {
  interface Window {
    international: boolean;
    isMECApp: boolean;
  }
}

export namespace Registration {
  // Private variables

  // HTML ID ENUM
  enum ELEM {
    REG_FORM_DIV = "paj-regform",
    LOGIN_FORM_DIV = "paj-loginform",
    OTP_FORM = "paj-verifyform",
    MOBILE_INPUT_FIELD = "mobile_input",
    OTP_INPUT_FIELD = "otpInputField",
    EMAIL_INPUT_FIELD = "email_input",
  }

  // CSS Enum
  enum CSS {
    HIDE = "hide",
  }

  enum PATH {
    PROFILE = "/profile.html",
    PROFILE_INTERNATIONAL = "/profile-international.html",
    GAME_SELECTOR = "/gameSelector.html",
    MORE_MENU = "/moreMenu.html",
    HOME_PAGE = "/classic/home",
    BASE = "/my11c/static",
  }

  let redirect = redirectWithBase(PATH.GAME_SELECTOR);
  let regView: RegistrationView = new RegistrationView();
  let otpVerifyView: OtpVerificationView = new OtpVerificationView();
  let myProfile: MyProfileView = new MyProfileView();
  let myInternationalProfile: MyProfileInternationalView =
    new MyProfileInternationalView();
  let externalRegView: ExternalUserRegView = new ExternalUserRegView();
  let extOtpVerifyView: OtpVerificationView = new OtpVerificationView();
  let moreMenuView: MoreMenuView = new MoreMenuView();

  // Private functions
  function init() {
    const urlParams: any = queryString.parse(window.location.search);
    window.isMECApp = Boolean(urlParams.ssid);

    if (urlParams.ru && urlParams.ru != "") {
      redirect = redirectWithBase(urlParams.ru);
    }
    if (window.international || window.isMECApp) {
      redirect = PATH.HOME_PAGE;
    }

    callForCheckLogin(redirect);
  }

  function redirectWithBase(redirect: string) {
    if (process.env.BUILD_TYPE === CHANNEL.IPL) {
      if (redirect.includes("/classic") || redirect.includes("/daily"))
        return redirect;
      return PATH.BASE + redirect;
    }
    return redirect;
  }

  function callForCheckLogin(redirect: string) {
    APIS.checkLogin2()
      .then((res) => {
        // set if international from session.
        const international = sessionStorage.getItem("international");
        window.international = international === "true";

        // redirect if on login page.
        if (window.location.pathname.includes("login")) {
          window.location.pathname = redirect;
          return;
        }

        const sourcePath = window.location.pathname;
        const targetPath = sourcePath.startsWith(PATH.BASE)
          ? sourcePath.slice(PATH.BASE.length)
          : sourcePath;

        switch (targetPath) {
          case PATH.PROFILE:
            return myProfile.init();
          case PATH.PROFILE_INTERNATIONAL:
            return myInternationalProfile.init();
          case PATH.MORE_MENU:
            return moreMenuView.init();
        }
      })
      .catch((e) => {
        // redirect if on some other page.
        if (!window.location.pathname.includes("login")) {
          window.location.pathname = redirectWithBase("/login.html");
          return;
        }

        if (window.international) {
          console.log("Check login error for external user: ");
          initExternalRegView();
          initExternalOtpView();
        } else {
          console.log("Check login error: ");
          initRegView();
          initOtpVerificationView();
        }

        // clear international from session.
        sessionStorage.removeItem("international");
      });
  }

  function initRegView() {
    hashHandler({});
    regView.showVerifyOtpScreen = showVerifyOtpScreen;
    regView.showOtpScreenForLogin = showOtpScreenForLogin;
    regView.hideVerifyOtpScreen = hideVerifyOtpScreen;
    regView.init();
  }

  function initOtpVerificationView() {
    otpVerifyView.showRegForm = showRegForm;
    otpVerifyView.showLoginForm = showLoginForm;
    otpVerifyView.init({
      redirect,
    });
  }

  function initExternalRegView() {
    // hashHandler({});
    externalRegView.showExtVerifyOtpScreen = showExtVerifyOtpScreen;
    externalRegView.hideVerifyOtpScreen = hideVerifyOtpScreen;
    externalRegView.init();
  }

  function initExternalOtpView() {
    extOtpVerifyView.showRegForm = showRegForm;
    extOtpVerifyView.init({
      redirect,
    });
  }

  function focusOnCurrentForm() {
    const mobileInput = document.getElementById(ELEM.MOBILE_INPUT_FIELD);
    const otpInput = document.getElementById(ELEM.OTP_INPUT_FIELD);
    const emailInput = document.getElementById(ELEM.EMAIL_INPUT_FIELD);

    if (window.international) {
      const regFormContainer = document.getElementById(ELEM.REG_FORM_DIV);
      if (regFormContainer && !regFormContainer.classList.contains(CSS.HIDE)) {
        emailInput?.focus();
      }
    } else {
      const regFormContainer = document.getElementById(ELEM.REG_FORM_DIV);
      if (regFormContainer && !regFormContainer.classList.contains(CSS.HIDE)) {
        mobileInput?.focus();
      }
    }

    const otpFormContainer = document.getElementById(ELEM.OTP_FORM);
    if (otpFormContainer && !otpFormContainer.classList.contains(CSS.HIDE)) {
      otpInput?.focus();
    }
  }

  function showRegForm() {
    hideVerifyOtpScreen();

    let regFormContainer = document.getElementById(
      ELEM.REG_FORM_DIV
    ) as HTMLFormElement;
    removeCssClass(regFormContainer, CSS.HIDE);
    trackEvent("view_displayed", "registration_screen_loaded");
    focusOnCurrentForm();
  }

  function hideRegForm() {
    let regFormContainer = document.getElementById(
      ELEM.REG_FORM_DIV
    ) as HTMLFormElement;
    addCssClass(regFormContainer, CSS.HIDE);
  }

  function hideLoginForm() {
    let loginFormContainer = document.getElementById(
      ELEM.LOGIN_FORM_DIV
    ) as HTMLFormElement;
    addCssClass(loginFormContainer, CSS.HIDE);
  }

  function showOtpScreenForLogin(
    loginId: string,
    reasonCode: number,
    challenge: string,
    transactionId: number,
    userInput: number
  ) {
    hideRegForm();
    hideLoginForm();

    otpVerifyView.setIsRegJourney(false);
    otpVerifyView.setLoginId(loginId);
    otpVerifyView.setChallenge(challenge);
    otpVerifyView.setTransactionId(transactionId);
    otpVerifyView.setUserInputValue(userInput);
    otpVerifyView.setReasonCode(reasonCode);
    otpVerifyView.resetOtpScreen();
    otpVerifyView.initResendTimer();

    let otpFormContainer = document.getElementById(
      ELEM.OTP_FORM
    ) as HTMLFormElement;
    removeCssClass(otpFormContainer, CSS.HIDE);
    trackEvent("view_displayed", "verify_with_otp_screen_shown", {
      otp_initiation_point: "login_screen",
    });
  }

  function showVerifyOtpScreen(mobileNum: string, inviteCode: string) {
    hideRegForm();

    otpVerifyView.resetOtpScreen();
    otpVerifyView.setIsRegJourney(true);
    otpVerifyView.setMobileNum(mobileNum);
    otpVerifyView.setInviteCode(inviteCode);
    otpVerifyView.initResendTimer();

    let otpFormContainer = document.getElementById(
      ELEM.OTP_FORM
    ) as HTMLFormElement;
    removeCssClass(otpFormContainer, CSS.HIDE);
    otpVerifyView.hidePasswordLink();

    otpVerifyView.initFetchOtp();
    trackEvent("view_displayed", "verify_with_otp_screen_shown", {
      otp_initiation_point: "registration_screen",
    });
    focusOnCurrentForm();
  }

  function showExtVerifyOtpScreen(email: string) {
    hideRegForm();

    extOtpVerifyView.resetOtpScreen();
    extOtpVerifyView.setIsRegJourney(true);
    extOtpVerifyView.setEmail(email);
    extOtpVerifyView.initResendTimer();

    let otpFormContainer = document.getElementById(
      ELEM.OTP_FORM
    ) as HTMLFormElement;
    removeCssClass(otpFormContainer, CSS.HIDE);

    extOtpVerifyView.invokeSendEmailExternal();
    trackEvent("view_displayed", "verify_with_otp_screen_shown", {
      otp_initiation_point: "registration_screen",
    });
    focusOnCurrentForm();
  }

  function hideVerifyOtpScreen() {
    let otpFormContainer = document.getElementById(
      ELEM.OTP_FORM
    ) as HTMLFormElement;
    addCssClass(otpFormContainer, CSS.HIDE);
  }

  function showLoginForm() {
    hideVerifyOtpScreen();
    let loginFormContainer = document.getElementById(
      ELEM.LOGIN_FORM_DIV
    ) as HTMLFormElement;
    removeCssClass(loginFormContainer, CSS.HIDE);
    trackEvent("view_displayed", "login_screen_loaded");
    focusOnCurrentForm();
  }

  function addCssClass(elem: HTMLElement, className: string) {
    if (elem && !elem.classList.contains(className)) {
      elem.classList.add(className);
    }
  }

  function removeCssClass(elem: HTMLElement, className: string) {
    if (elem && elem.classList.contains(className)) {
      elem.classList.remove(className);
    }
  }

  function hashHandler(event: any) {
    const { oldURL } = event;
    const hash = window.location.hash;

    switch (hash) {
      case "#login":
        regView.switchToLoginJourney();
        break;
      case "#register":
        regView.switchToRegJourney();
        break;
      default:
        break;
    }
  }

  // Public properties
  // export var createYear = 2018;
  // Public Methods
  export function onLoadInit() {
    var unloadHandler = function () {
      window.removeEventListener("unload", unloadHandler, false);
      window.removeEventListener("hashchange", hashHandler, false);
    };
    window.addEventListener("load", init);
    window.addEventListener("unload", unloadHandler, false);
    window.addEventListener("hashchange", hashHandler, false);
  }
}

Registration.onLoadInit();
