import {
  getChannelId,
  getLocalStorageItem
} from "../../../common/utils/common";
import RegistrationBaseView from "../../../common/views/RegistrationBaseView";
import { trackEvent } from "../../../common/utils/common";
import { validations } from "../../../common/utils/validate";

enum ELEM {
  REG_FORM_CONT = "paj-regform",
  LOGIN_FORM_CONT = "paj-loginform",
  EMAIL_INPUT_FIELD = "email_input",
  REGISTER_CTA = "registerCTA",
  LOGIN_CTA = "loginCTA",
  MSG_INVALID_EMAIL = "msg_invalid_email",
  EMAIL_INPUT_CLEAR_BTN = "email_input_clear_btn",
  EMAIL_INPUT_BOX = "email_input_box",
}

enum CSS {
  SHAKE_ANIMATION = "shake-anim",
}

enum MSG {
  REG_TITLE = "LOGIN/SIGNUP",
  LOGIN_BTN_LABEL = "LOGIN",
  INVALID_EMAIL_MSG = "Please enter a valid email address",
  EMPTY_EMAIL_MSG = "Please enter your email address",
}

enum HASH {
  LOGIN = "#login",
  REGISTER = "#register",
}

class ExternalUserRegView extends RegistrationBaseView {
  constructor() {
    super();
  }

  trackEvent(str1: string, str2: string) {}

  init() {
    this.checkIsRegJourney();
    this.assignEventListeners();
    trackEvent("view_displayed", "registration_screen_loaded");

    const emailInputField = document.getElementById(
      ELEM.EMAIL_INPUT_FIELD
    ) as HTMLInputElement;

    const storedEmail = getLocalStorageItem("email");
    if (storedEmail && !emailInputField.value) {
      emailInputField.value = storedEmail;
    }

    setTimeout(() => {
      emailInputField.focus();
    }, 1000);
  }

  private checkIsRegJourney() {
    if (
      window.location.hash === HASH.LOGIN ||
      (window.location.search.indexOf("source=logout") != -1 &&
        getChannelId() == 1)
    ) {
      this.isRegJourney = false;
    } else {
      this.isRegJourney = true;
    }
  }

  private assignEventListeners() {
    let registerCTABtn = document.getElementById(
      ELEM.REGISTER_CTA
    ) as HTMLDivElement;
    registerCTABtn.addEventListener("click", this.onContinueClick.bind(this));

    let loginCTABtn = document.getElementById(ELEM.LOGIN_CTA) as HTMLDivElement;
    loginCTABtn.addEventListener("click", this.onContinueClick.bind(this));

    const emailInputField = document.getElementById(
      ELEM.EMAIL_INPUT_FIELD
    ) as HTMLInputElement;
    emailInputField.addEventListener(
      "input",
      this.onChangeEmailInput.bind(this)
    );

    let emailInputClearBtn = document.getElementById(
      ELEM.EMAIL_INPUT_CLEAR_BTN
    ) as HTMLSpanElement;
    emailInputClearBtn.addEventListener(
      "click",
      this.onClickClearEmailInput.bind(this)
    );
  }

  onContinueClick() {
    if (this.isRegJourney) {
      this.startRegForExternal();
    }
  }

  startRegForExternal() {
    let emailInputBox = document.getElementById(
      ELEM.EMAIL_INPUT_BOX
    ) as HTMLDivElement;

    this.emailId = (
      document.getElementById(ELEM.EMAIL_INPUT_FIELD) as HTMLInputElement
    ).value.trim();

    this.removeCssClass(emailInputBox, CSS.SHAKE_ANIMATION);
    if (this.emailId.length == 0) {
      trackEvent("view_displayed", "mobile_number_error_shown", {
        "error message": "empty email",
      });
      this.showInvalidEmailError(MSG.EMPTY_EMAIL_MSG);
      setTimeout(() => {
        this.addCssClass(emailInputBox, CSS.SHAKE_ANIMATION);
      }, 0);
      return;
    } 
    if (!validations.isEmail(this.emailId)) {
      trackEvent("view_displayed", "mobile_number_error_shown", {
        "error message": "invalid email",
      });
      this.showInvalidEmailError();
      setTimeout(() => {
        this.addCssClass(emailInputBox, CSS.SHAKE_ANIMATION);
      }, 0);
      return;
    }

    this.resetForExternalUser();
    this.showExtVerifyOtpScreen(this.emailId);
  }
}

export default ExternalUserRegView;

