import {
  getChannelId,
  getLocalStorageItem,
} from "../../../common/utils/common";
import RegistrationBaseView from "../../../common/views/RegistrationBaseView";
import { trackEvent } from "../../../common/utils/common";
import { validations } from "../../../common/utils/validate";
import { APIS } from "../../../common/utils/api";

enum ELEM {
  REG_FORM_CONT = "paj-regform",
  LOGIN_FORM_CONT = "paj-loginform",
  MOBILE_INPUT_FIELD = "mobile_input",
  REGISTER_CTA = "registerCTA",
  LOGIN_CTA = "loginCTA",
  MOBILE_INPUT_BOX = "mobile_input_box",
  MSG_INVALID_MOBILE = "msg_invalid_mobile",
  MSG_INVALID_EMAIL = "msg_invalid_email",
  MOBILE_INPUT_CLEAR_BTN = "mobile_input_clear_btn",
}

enum CSS {
  HIDE = "hide",
  INPUT_ERR_BORDER = "error-border",
  INPUT_ERR_BTM_BORDER = "error-btmborder",
  SHAKE_ANIMATION = "shake-anim",
}

enum MSG {
  REG_TITLE = "LOGIN/SIGNUP",
  SWITCH_TO_LOGIN_TXT = "Login using other methods",
  SWITCH_TO_REG_TXT = "Login / Register using mobile",
  MOBILE_INPUT_PLACEHOLDER_TXT = "Enter Mobile Number",
  EMAIL_INPUT_PLACEHOLDER_TXT = "Enter email address / username",
  GET_STARTED_BTN_LABEL = "GET STARTED",
  LOGIN_BTN_LABEL = "LOGIN",
  INVALID_MOBILE_MSG = "Please enter a valid mobile number",
  INVALID_EMAIL_MSG = "Enter a valid Email address / username",
  INVALID_USERNAME_MSG = "Enter a valid Username",
  EMPTY_MOBILE_MSG = "Please enter your mobile number",
}

enum HASH {
  LOGIN = "#login",
  REGISTER = "#register",
}

class RegistrationView extends RegistrationBaseView {
  constructor() {
    super();
  }

  trackEvent(str1: string, str2: string) {}

  init() {
    this.checkIsRegJourney();
    this.assignEventListeners();

    if (!this.isRegJourney) {
      this.switchToLoginJourney();
      trackEvent("view_displayed", "login_screen_loaded");
    } else {
      trackEvent("view_displayed", "registration_screen_loaded");
    }
    const mobileInputField = document.getElementById(
      ELEM.MOBILE_INPUT_FIELD
    ) as HTMLInputElement;

    const storedMobile = getLocalStorageItem("mobile");
    if (storedMobile && !mobileInputField.value) {
      mobileInputField.value = storedMobile;
    }

    setTimeout(() => {
      mobileInputField.focus();
    }, 1000);
  }

  private checkIsRegJourney() {
    if (
      window.location.hash === HASH.LOGIN ||
      (window.location.search.indexOf("source=logout") != -1 &&
        getChannelId() == 1)
    ) {
      this.isRegJourney = false;
    } else {
      this.isRegJourney = true;
    }
  }

  private assignEventListeners() {
    let registerCTABtn = document.getElementById(
      ELEM.REGISTER_CTA
    ) as HTMLDivElement;
    registerCTABtn.addEventListener("click", this.onContinueClick.bind(this));

    let loginCTABtn = document.getElementById(ELEM.LOGIN_CTA) as HTMLDivElement;
    loginCTABtn.addEventListener("click", this.onContinueClick.bind(this));

    let mobileInputField = document.getElementById(
      ELEM.MOBILE_INPUT_FIELD
    ) as HTMLInputElement;
    mobileInputField.addEventListener(
      "input",
      this.onChangeMobileInput.bind(this)
    );

    let mobileInputClearBtn = document.getElementById(
      ELEM.MOBILE_INPUT_CLEAR_BTN
    ) as HTMLSpanElement;
    mobileInputClearBtn.addEventListener(
      "click",
      this.onClickClearMobileInput.bind(this)
    );
  }

  onContinueClick() {
    if (this.isRegJourney) {
      this.startRegJourney();
    } else {
      this.startLoginJourney();
    }
  }

  resetView() {
    let mobileInputBox = document.getElementById(
      ELEM.MOBILE_INPUT_BOX
    ) as HTMLDivElement;
    this.removeCssClass(mobileInputBox, CSS.INPUT_ERR_BORDER);

    let errorMsgMobile = document.getElementById(
      ELEM.MSG_INVALID_MOBILE
    ) as HTMLParagraphElement;
    let errorMsgEmail = document.getElementById(
      ELEM.MSG_INVALID_EMAIL
    ) as HTMLParagraphElement;
    this.addCssClass(errorMsgMobile, CSS.HIDE);
    this.addCssClass(errorMsgEmail, CSS.HIDE);
    this.hideVerifyOtpScreen();
  }

  switchToLoginJourney() {
    this.resetView();
    this.isRegJourney = false;
    this.mobileNum = "";
    let regFormCont = document.getElementById(
      ELEM.REG_FORM_CONT
    ) as HTMLFormElement;
    this.addCssClass(regFormCont, CSS.HIDE);
    let loginFormCont = document.getElementById(
      ELEM.LOGIN_FORM_CONT
    ) as HTMLFormElement;
    this.removeCssClass(loginFormCont, CSS.HIDE);

    let mobileInputField = document.getElementById(
      ELEM.MOBILE_INPUT_FIELD
    ) as HTMLInputElement;
    mobileInputField.value = "";
    mobileInputField.placeholder = MSG.EMAIL_INPUT_PLACEHOLDER_TXT;
    mobileInputField.maxLength = 50;
    mobileInputField.type = "email";
    window.location.hash = HASH.LOGIN;
  }

  switchToRegJourney() {
    this.resetView();
    this.isRegJourney = true;
    this.loginId = "";
    let loginFormCont = document.getElementById(
      ELEM.LOGIN_FORM_CONT
    ) as HTMLFormElement;
    this.addCssClass(loginFormCont, CSS.HIDE);
    let regFormCont = document.getElementById(
      ELEM.REG_FORM_CONT
    ) as HTMLFormElement;
    this.removeCssClass(regFormCont, CSS.HIDE);

    let mobileInputField = document.getElementById(
      ELEM.MOBILE_INPUT_FIELD
    ) as HTMLInputElement;
    mobileInputField.value = "";
    mobileInputField.placeholder = MSG.MOBILE_INPUT_PLACEHOLDER_TXT;
    mobileInputField.maxLength = 10;
    mobileInputField.type = "tel";
    trackEvent("view_displayed", "registration_screen_loaded");
    window.location.hash = HASH.REGISTER;
  }

  startRegJourney() {
    this.mobileNum = (
      document.getElementById(ELEM.MOBILE_INPUT_FIELD) as HTMLInputElement
    ).value.trim();

    if (!validations.isMobileNumber(this.mobileNum)) {
      this.handleMobileError(this.mobileNum);
      return;
    }

    this.resetView();
    this.showVerifyOtpScreen(this.mobileNum, this.inviteCode);
  }

  handleMobileError(mobileNumber: string) {
    let mobileInputBox = document.getElementById(
      ELEM.MOBILE_INPUT_BOX
    ) as HTMLDivElement;
    let errorMsg = document.getElementById(
      ELEM.MSG_INVALID_MOBILE
    ) as HTMLParagraphElement;

    this.addCssClass(errorMsg, CSS.HIDE);
    this.removeCssClass(mobileInputBox, CSS.INPUT_ERR_BORDER);
    this.removeCssClass(mobileInputBox, CSS.SHAKE_ANIMATION);

    if (!mobileNumber) {
      errorMsg.innerHTML = MSG.EMPTY_MOBILE_MSG;
      trackEvent("view_displayed", "empty_mobile");
    } else {
      errorMsg.innerHTML = MSG.INVALID_MOBILE_MSG;
      trackEvent("view_displayed", "invalid_mobile");
    }

    setTimeout(() => {
      this.removeCssClass(errorMsg, CSS.HIDE);
      this.addCssClass(mobileInputBox, CSS.INPUT_ERR_BORDER);
      this.addCssClass(mobileInputBox, CSS.SHAKE_ANIMATION);
    }, 0);
  }
}

export default RegistrationView;
